import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppService } from './app.service';
import { RequestService } from './request.service';
import { SiteConfiguration, Post } from 'src/app/interface/parameters';

@Injectable({
    providedIn: 'root'
})
export class HomeService {

    private siteHomeConfigurationSubject = new Subject<SiteConfiguration>();
    public siteHomeConfiguration$ = this.siteHomeConfigurationSubject.asObservable();
    public siteHomeConfiguration: SiteConfiguration;

    private siteHomeConfigurationPreviewSubject = new Subject<any>();
    public siteHomeConfigurationPreview$ = this.siteHomeConfigurationPreviewSubject.asObservable();
    public siteHomeConfigurationPreview: SiteConfiguration;
    public urlPortal: string = 'https://www.papoimobiliario.com';
    public posts: Array<Post>;

    constructor(
        private appService: AppService,
        private requestService: RequestService
    ) {
        this.siteHomeConfiguration$.subscribe(siteHomeConfiguration => {
            this.siteHomeConfiguration = siteHomeConfiguration
        });
        this.siteHomeConfigurationPreview$.subscribe(siteHomeConfigurationPreview => {
            this.siteHomeConfigurationPreview = siteHomeConfigurationPreview
        });
    }

    public getSiteConfiguration(): Observable<SiteConfiguration> {
        return new Observable<SiteConfiguration>(observer => {
            this.requestService.get<SiteConfiguration>(
                `${this.appService.apiUrl()}/${this.appService.namespace()}/site2/site-pages-configuration`).subscribe(
                    response => {
                        this.siteHomeConfigurationSubject.next(response)
                        observer.next(response)
                    },
                    error => observer.error('Erro: ' + error)
                );
        });
    }

    getSitePreviewData(key: string): Observable<SiteConfiguration> {
        return new Observable<any>(observer => {
            this.requestService.get(`${this.appService.apiUrl()}/site-preview/${key}`).subscribe(
                response => {
                    this.siteHomeConfigurationPreviewSubject.next(response)
                    observer.next(response)
                },
                error => {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    getPosts(): Observable<Array<Post>> {
        return new Observable<Array<Post>>(observer => {
            this.requestService.get<Array<Post>>(`${this.urlPortal}/wp-json/wp/v2/posts`).subscribe(
                response => {
                    this.posts = response;
                    observer.next(response);
                },
                error => observer.error('Erro: ' + error),
                () => observer.complete()
            );
        });
    };
}
