<imobzi-button-close-modal (closeChange)="closeDialog($event)"></imobzi-button-close-modal>

<section id="contact-container" class="mat-dialog-content background-title col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

  <div class="form-contact col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
    <imobzi-section-title
      [title]="title"
      [subtitle]="subtitle"
      [class.step_success]="displaySuccess">
    </imobzi-section-title>

    @if (!displaySuccess) {
      <imobzi-step-form
        [formContent]="formContent"
        [buttonTitle]="buttonTitle"
        [loading]="loading"
        (formSubmit)="onFormSubmit($event)">
      </imobzi-step-form>
    }

    @if (displaySuccess) {
      <imobzi-success-form
        [msgSuccessTitle]="msgSuccessTitle"
        [msgSuccessSubtitle]="msgSuccessSubtitle"
        (btnAction)="onBtnChanged($event)">
      </imobzi-success-form>
    }
  </div>

  <div class="info-container col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
    @if (siteConfiguration) {
      <img [src]="siteConfiguration.footer_logo" alt="Logotipo de {{parameters?.company_name}}">
    }
    <imobzi-address class="col-md-12" [parameters]="parameters"></imobzi-address>
    <imobzi-phone class="col-md-12" [parameters]="parameters"></imobzi-phone>
    <imobzi-email class="col-md-12" [parameters]="parameters"></imobzi-email>
    <imobzi-social-network class="col-md-12" [parameters]="parameters"></imobzi-social-network>
  </div>
</section>
