import { Component, Input } from '@angular/core';
import { Parameters } from 'src/app/interface/parameters';
import { Property } from 'src/app/interface/property';
import { translateStatus } from 'src/app/utils/utils';


@Component({
    selector: 'imobzi-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss']
})
export class TagsComponent {

    @Input() parameters: Parameters;
    @Input() properties: Property;
    public status: string;
    constructor() { }

    translateStatus = (status: string) => translateStatus(status)
    

}
