<header id="main-header" class="site-header" [class.header-border]="!calledFromHome"
    [class.header-relative]="searchButtons === 'banner'" [style.--background-color]="backgroundType">
    <section 
        id="grid-menu" 
        [class.container]="!isSmallScreen"
        class="grid-xs-12 grid-sm-12 grid-md-12 grid-lg-12 grid-xl-12"
    >

        @if (!siteConfiguration) {
            <div class="logo-content shinny gd-xs-1-9 gd-sm-1-5 gd-md-1-4 gd-lg-1-3 gd-xl-1-2"></div>
            <div
                class="grid-xs-12-12 grid-sm-5 grid-md-5 grid-lg-7 grid-xl-5 gd-xs-11-12 gd-sm-6-12 gd-md-6-12 gd-lg-8-12 gd-xl-8-12">
                <a
                    class="button-load shinny imobzi-area-cliente btn btn-md gd-xs-3-4 gd-sm-3-4 gd-md-3-4 gd-lg-4-6 gd-xl-3-4 col-xs-9 col-sm-12 col-md-10 col-lg-9-12 col-xl-9"></a>
                <button 
                    class="menu-load shinny menu btn-icon gd-xs-12-12 gd-sm-5-5 gd-md-5-5 gd-lg-7-7 gd-xl-5-5"
                    type="button">
                </button>
            </div>
        } @else {
            <a routerLink="/" (click)="removeScroll()" class="gd-xs-1-9 gd-sm-1-5 gd-md-1-4 gd-lg-1-3 gd-xl-1-2"
                aria-label="Logo">
                @if (calledFromHome) {
                    <img [src]="logoUrl" alt="Logotipo de {{parameters?.company_name}}">
                } @else {
                    <img [src]="internalLogoUrl" alt="Logotipo de {{parameters?.company_name}}">
                }
            </a>

            @if (parameters && headerType === 'Menu') {
                <imobzi-header-menu 
                    class="imobzi-area-cliente gd-md-5-8 gd-lg-4-7 gd-xl-3-7" 
                    [parameters]="parameters"
                    [calledFromHome]="calledFromHome" 
                    [mainMenu]="parameters.site_menus['main-menu']"
                    [siteConfiguration]="siteConfiguration" 
                    [fontColor]="fontColor" 
                />
            }

            @if (parameters) {
                <div
                    class="grid-xs-12-12 grid-sm-5 grid-md-5 grid-lg-7 grid-xl-5 gd-xs-11-12 gd-sm-6-12 gd-md-6-12 gd-lg-8-12 gd-xl-8-12">
                    @if (headerType === 'Menu' && phone) {
                        <div class="imobzi-area-cliente phone font-color gd-xs-1-2 gd-sm-1-2 gd-md-1-2 gd-lg-1-3 gd-xl-1-2 col-xs-9 col-sm-12 col-md-10 col-lg-9-12 col-xl-9 bold"
                            [class.font-light]="fontColor === 'white'"
                            [class.font-dark]="fontColor === 'dark'" (click)="onPhoneClicked(phone)">
                            <fa-icon [icon]="['fab', 'whatsapp']"></fa-icon>
                            {{phone.number}}
                        </div>
                    }

                    @if (
                        parameters.site_home_button.active 
                        && parameters.site_home_button.button_type !== 'property_filter' 
                        && headerType !== 'Menu'
                    ) {
                        <imobzi-custom-button
                            class="imobzi-area-cliente btn btn-md gd-xs-1-2 gd-sm-1-2 gd-md-1-2 gd-lg-1-3 gd-xl-1-2 col-xs-9 col-sm-12 col-md-10 col-lg-9-12 col-xl-9"
                            [buttonHome]="parameters.site_home_button"
                            [class.btn-light-1]="fontColor === 'white'"
                            [class.btn-disabled-2]="fontColor === 'dark'" 
                        />
                    }

                    @if (parameters.client_area) {
                        <imobzi-area-cliente
                            class="imobzi-area-cliente btn btn-md gd-xs-3-4 gd-sm-3-4 gd-md-3-4 gd-lg-4-6 gd-xl-3-4 col-xs-9 col-sm-12 col-md-10 col-lg-9-12 col-xl-9"
                            [class.btn-light-1]="fontColor === 'white'"
                            [class.btn-disabled-2]="fontColor === 'dark'"
                        />
                    } @else {
                        <a 
                            routerLink="/anuncie-seu-imovel"
                            class="bold imobzi-area-cliente btn btn-md gd-xs-3-4 gd-sm-3-4 gd-md-3-4 gd-lg-4-6 gd-xl-3-4 col-xs-9 col-sm-12 col-md-10 col-lg-9-12 col-xl-9"
                            [class.btn-light-1]="fontColor === 'white'"
                            [class.btn-disabled-2]="fontColor === 'dark'">
                            {{ 'Anuncie seu imóvel' | uppercase }}
                        </a>
                    }

                    @if (headerType === 'Hamburguer' || isSmallScreen) {
                        <button 
                            class="menu btn-icon gd-xs-12-12 gd-sm-5-5 gd-md-5-5 gd-lg-7-7 gd-xl-5-5"
                            [class.btn-light-1]="fontColor === 'white'"
                            [class.btn-primary-1]="fontColor === 'dark'" 
                            type="button"
                            aria-label="Button Menu" 
                            (click)="close()">
                            <mat-icon class="icon-inverted material-icons-round" aria-label="Menu">notes</mat-icon>
                        </button>
                    }
                </div>
            }
        }
    </section>
</header>

@if (calledFromHome && siteConfiguration) {
    <imobzi-search-bar 
        [parameters]="parameters" 
        [clientBucketPath]="clientBucketPath"
        [siteConfiguration]="siteConfiguration" 
    />
}