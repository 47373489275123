<section id="fab-button">
    @if (isCardVisible) {
        <div class="card-online"
            [class.floating-left]="parameters.button_floating_direction === 'left'"
            [class.floating-right]="parameters.button_floating_direction !== 'left'"
            [ngClass]="{'visible': isVisible && parameters}">
            <div class="card-header">
            <span [class.online]="online" [class.offline]="!online">
                {{ online ? 'Online' : 'Offline' }}
            </span>
            <button class="close-button" (click)="hideCard()">
                <mat-icon aria-label="Close menu">close</mat-icon>
            </button>
            </div>
            <h3 class="descripton bold">Está com dificuldades para achar o imóvel ideal?<br>Fale com um de nossos representantes!</h3>
            <button class="btn btn-md btn-primary-1 bold" (click)="onPhoneClicked()">Fale com um representante</button>
        </div>
    }

    <div
        class="fab-container"
        [class.floating-left]="parameters.button_floating_direction === 'left'"
        [class.floating-right]="parameters.button_floating_direction !== 'left'">
        <button mat-fab class="fab-toggler" (click)="onToggleFab()">
            <img class="fab_toggler_icons" [src]="layoutBucketPath + 'contact.svg'" alt="contato">
        </button>
        <div [@speedDialStagger]="buttons.length">
        @for (btn of buttons; track btn.icon) {
            <button
            [class.whatsapp]="btn.icon === 'whatsapp'"
            (click)="openDialog(btn.icon); onToggleFab()"
            mat-mini-fab class="fab-secondary"
            color="secondary">
            <fa-icon class="fab-icon" [icon]="[btn.iconType, btn.icon]"></fa-icon>
            </button>
        }
        </div>
    </div>
    @if (fabTogglerState === 'active') {
        <div id="fab-dismiss" (click)="onToggleFab()"></div>
    }
</section>
