<section id="listingBroker"  class="container" fxLayout="column" fxLayoutAlign="space-between center">
  @if (listingBroker && listingBroker.license) {
    <img class="fundo_img" [src]="layoutBucketPath + 'city-background.svg'" alt="fundo">
    <div class="listingBroker-container col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
      fxLayout="column" fxLayoutAlign="space-between center">
      <figure>
        @if (listingBroker) {
          <img
            class="img-circle img-responsive"
            [src]="listingBroker.profile_image_url"
            (error)="realtorImgErrorHandler($event)"
            alt="Corretor {{ listingBroker.name }}"
            title="Corretor {{ listingBroker.name }}">
        }
      </figure>
      @if (listingBroker.name) {
        <h2 class="text-center">{{ listingBroker.name }}</h2>
      }
      @if (listingBroker.license) {
        <h3 class="text-center">CRECI {{ listingBroker.license }}</h3>
      }
    </div>
    <button
      (click)="openTalkBrokerDialog()"
      class="margin-10 relative btn btn-md btn-primary-1 col-xs-12 col-sm-6 col-md-12 col-lg-12 col-xl-12">
      {{ 'Enviar mensagem' | uppercase }}
    </button>
    <button
      [routerLink]="['corretor/' + listingBroker.nickname]"
      [queryParams]="{ nickname: listingBroker.nickname }"
      (click)="onBtnChanged(true)"
      class="margin-10 relative btn btn-md btn-disabled-2 col-xs-12 col-sm-6 col-md-12 col-lg-12 col-xl-12">
      {{ 'Imóveis deste corretor (' + listingBroker.count + ')' | uppercase }}
    </button>
  } @else {
    <img class="fundo_img" [src]="layoutBucketPath + 'city-background.svg'" alt="fundo">
    <div class="listingBroker-container col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
      fxLayout="column" fxLayoutAlign="space-between center">
      <figure>
        <img class="img-circle img-responsive"
          [src]="layoutBucketPath + 'realtor.png'"
          (error)="realtorImgErrorHandler($event)"
          alt="Imobiliaria"
          title="Imobiliaria">
      </figure>
      @if (parameters.company_name) {
        <h2 class="text-center"
          >
          {{ parameters.company_name }}
        </h2>
      }
      @if (parameters.creci) {
        <h3 class="text-center"
          >
          {{ 'CRECI ' + parameters.creci }}
        </h3>
      }
    </div>
    <button
      (click)="openTalkBrokerDialog()"
      class="margin-10 relative btn btn-md btn-primary-1 col-xs-12 col-sm-6 col-md-12 col-lg-12 col-xl-12">
      {{ 'Enviar mensagem' | uppercase }}
    </button>
  }


  <imobzi-phone-numbers
    class="col-xs-12 col-sm-6 col-md-12 col-lg-12 col-xl-12"
    [parameters]="parameters"
    [property]="property"
    [showWhatsapp]="true"
    [showPhone]="true">
  </imobzi-phone-numbers>
</section>