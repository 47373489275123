<imobzi-button-close-modal (closeChange)='closeDialog($event)'/>

<section class="multimedia-container">
    <header class="multi-media-header">
        <swiper-container class="swiper-container" appSwiper [config]="swiperCategories" #swiper>
            @for (category of categories; track $index) {
                <swiper-slide class="swiper-slide">
                    <nav class="multi-media-itens">
                        <img [src]="category.photoUrl" alt="{{ category.name }}"
                            (click)="selectCategory(category.name)">
                        {{ category.name === 'photos' ? 'Fotos' : category.name }}
                    </nav>
                </swiper-slide>
            }
        </swiper-container>
    </header>

    @for(category of categories; track $index) {
        <div class="container-image" [id]="category.name">
            <h3>{{ category.name === 'photos' ? 'Fotos' : category.name }}</h3>
            <div class="grid-container">
                @for (photo of PhotosByCategory[category.name]; track $index) {
                    <img (click)="openDialogGallery(data.photos, $index)" [src]="photo.url" alt="{{ photo.description }}">
                }
            </div>
        </div>
    }
</section>
