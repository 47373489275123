import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { PropertyMultimidia } from 'src/app/interface/property';

@Component({
    selector: 'imobzi-gallery-modal',
    templateUrl: './gallery-modal.component.html',
    styleUrls: ['./gallery-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryModalComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('thumb') thumb: HTMLElement;
    @ViewChild('galleryContainer', { static: false }) galleryContainer: ElementRef;
    @ViewChild('thumbsContainer', { static: false }) thumbsContainer: ElementRef;

    public displayMidia: boolean;
    public displayAction: boolean;
    private breakpoint: any;
    public galleryTop = {
        spaceBetween: 10,
        slidesPerView: 'auto',
        lazy: true,
        effect: 'slide',
        slideToClickedSlide: false,
        loop: true,
        watchOverflow: true,
        speed: 600,
        direction: 'horizontal',
        pagination: {
            el: '.gallery-pagination',
            type: 'fraction',
            clickable: true,
            renderFraction(currentClass, totalClass) {
                return '<span class="' + currentClass + '"></span>' +
                    ' / ' +
                    '<span class="' + totalClass + '"></span>';
            }
        },
    };

    public galleryThumbs = {
        spaceBetween: 10,
        slidesPerView: 'auto',
        lazy: true,
        effect: 'slide',
        centeredSlides: false,
        slideToClickedSlide: true,
        loop: true,
        speed: 600,
        grabCursor: true,
        width: 140
    };

    constructor(
        public dialogRef: MatDialogRef<GalleryModalComponent>,
        private breakpointObserver: BreakpointObserver,
        private sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        this.getBreakpoints();
    }

    sanitizeUrl(value: any) {
        if (value && value.changingThisBreaksApplicationSecurity) {
            if (typeof value.changingThisBreaksApplicationSecurity == 'string') {
                return this.sanitizer.bypassSecurityTrustResourceUrl(
                    value.changingThisBreaksApplicationSecurity
                );
            }

            const innerValue = value.changingThisBreaksApplicationSecurity;

            if (innerValue.changingThisBreaksApplicationSecurity) {
                return this.sanitizer.bypassSecurityTrustResourceUrl(
                    innerValue.changingThisBreaksApplicationSecurity
                );
            }
        }

        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    }

    ngOnInit() {
        this.galleryTop['loopedSlides'] = this.data.path.midia.length;
        this.galleryThumbs['loopedSlides'] = this.data.path.midia.length;

        this.data.path.midia.forEach((midia: PropertyMultimidia, index: number) => {
            (index < 1)
                ? this.displayAction = true
                : this.displayAction = false;

            if (midia.category === 'videos' || midia.category === 'vídeos' || midia.category === 'virtual_tour') {
                this.displayMidia = true;
            } else {
                midia.category = 'photos';
                this.displayMidia = false;
            }
        });
    }

    ngAfterViewInit() {
        this.galleryContainer.nativeElement.swiper.activeIndex = this.data.path.index;
        this.thumbsContainer.nativeElement.swiper.activeIndex = this.data.path.index;
        this.getImgSize(this.data.path.index);
    }

    ngOnDestroy(): void {
        this.breakpoint.unsubscribe();
    }

    public getImgSize(index: number | any) {
        if (typeof index !== 'number') {
            index = index.detail[0].activeIndex;
        }

        const img = document.getElementById(`img-${index}`) as HTMLImageElement;
        if (img !== null) {
            const originalWidth = img.naturalWidth;
            const originalHeight = img.naturalHeight;
            const imageResolution = (originalWidth / originalHeight).toFixed(2);
            const containerResolution = (img.parentElement.clientWidth / img.parentElement.clientHeight).toFixed(2);
            if (originalWidth > originalHeight && imageResolution !== '1.33') {
                document.documentElement.style.setProperty('--object-fit', 'fill');
                const resolutionDiff = Number((Number(containerResolution) - Number(imageResolution)).toFixed(2));
                if ((0.25 >= resolutionDiff && resolutionDiff >= -0.25) && (originalWidth >= (img.parentElement.clientWidth - 300))) {
                    this.removeClassInHtmlElement(`#img-${index}`, 'image-natural-size');
                } else {
                    this.addClassInHtmlElement(`#img-${index}`, 'image-natural-size');
                }
            } else {
                document.documentElement.style.setProperty('--object-fit', 'contain');
            }
        }
    }

    private addClassInHtmlElement(elementId: string, className) {
        document.querySelectorAll(elementId).forEach(element => {
            if (!element.classList.contains(className)) {
                element.classList.add(className);
            }
        });
    }

    private removeClassInHtmlElement(elementId: string, className) {
        document.querySelectorAll(elementId).forEach(element => {
            if (element.classList.contains(className)) {
                element.classList.remove(className);
            }
        });
    }

    private getBreakpoints() {
        this.breakpoint = this.breakpointObserver.observe([
            Breakpoints.HandsetLandscape
        ]).subscribe(result => {
            if (isPlatformBrowser(this.platformId)) {
                this.setPortraitLayout(result.matches);
            }
        });
    }

    private setPortraitLayout(landscape) {
        if (landscape) {
            document.documentElement.style.setProperty('--display', 'none');
            document.documentElement.style.setProperty('--height', '100vh');
            document.documentElement.style.setProperty('--margin-top', '0');
        } else {
            document.documentElement.style.setProperty('--display', 'flex');
            document.documentElement.style.setProperty('--height', '40vh');
            document.documentElement.style.setProperty('--margin-top', '22vh');
        }

        if (landscape && this.breakpointObserver.isMatched('(min-width: 812px) and (max-width: 823px')) {
            document.documentElement.style.setProperty('--display', 'none');
            document.documentElement.style.setProperty('--height-tab', '100vh');
            document.documentElement.style.setProperty('--margin-top-tab', '0');
        } else {
            document.documentElement.style.setProperty('--display', 'flex');
            document.documentElement.style.setProperty('--height-tab', '54vh');
            document.documentElement.style.setProperty('--margin-top-tab', '16vh');
        }
    }

    swipePrev() {
        this.galleryContainer.nativeElement.swiper.slidePrev();
    }

    swipeNext() {
        this.galleryContainer.nativeElement.swiper.slideNext();
    }

    public closeModal(value: string) {
        if (value === 'close') {
            this.dialogRef.close();
        }
    }
}
