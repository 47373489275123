<mat-nav-list>
  <ul id="main-menu">

    @for (menu of menuItems[menuType]; track menu) {
      <li>
        <h2 class="item_menu">{{ menu.menu_name | uppercase}}</h2>
        @if (menu.submenus) {
          <ul class="submenusItems">
            @for (submenu of menu.submenus; track submenu) {
              @switch (submenu.menu_type) {
                @case ('property_filter') {
                  <li
                    class="submenu"
                    [class.active]="checkIfUrlFilterIsActive(submenu.url, submenu.query_params)"
                    [title]="submenu.group_item"
                    [attr.aria-label]="'link para filtro de imóveis - ' + submenu.group_item">
                    <a
                      [routerLink]="submenu.url"
                      [queryParams]="submenu.query_params">
                      {{ submenu.group_item }}
                    </a>
                  </li>
                }
                @case ('external_link') {
                  <li
                    [title]="submenu.group_item"
                    [attr.aria-label]="'link externo'"
                    class="submenu">
                    <a
                      [href]="submenu.url"
                      target="_blank"
                      [attr.aria-label]="submenu.group_item">
                      {{ submenu.group_item }}
                    </a>
                  </li>
                }
                @case ('linked_content') {
                  <li
                    [title]="submenu.group_item"
                    [routerLinkActive]="'active'"
                    [attr.aria-label]="'link de conteúdo - ' + submenu.group_item"
                    class="submenu">
                    <a
                      [routerLink]="submenu.url">
                      {{ submenu.group_item }}
                    </a>
                  </li>
                }
                @case ('internal_link') {
                  <li
                    [title]="submenu.group_item"
                    [routerLinkActive]="'active'"
                    [attr.aria-label]="'link interno - ' + submenu.group_item"
                    class="submenu">
                    <a
                      [routerLink]="submenu.url">
                      {{ submenu.group_item }}
                    </a>
                  </li>
                }
                @case ('modal') {
                  <li
                    [title]="submenu.group_item"
                    [attr.aria-label]="'Abrir modal - ' + submenu.group_item"
                    (click)="openDialog(submenu.modal)"
                    class="submenu">
                    <a>
                      {{ submenu.group_item }}
                    </a>
                  </li>
                }
              }
            }
          </ul>
        }
      </li>
    }
  </ul>
</mat-nav-list>
