import { Component, Input, OnInit, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { Parameters } from 'src/app/interface/parameters';
import { onPhoneClicked } from 'src/app/utils/utils';
import { fabButtonAnimations } from './fab-button.animations';
import { ContactRealEstateDialogComponent } from '../modal/contact-real-estate/contact-real-estate-dialog.component';
import { AppService } from 'src/app/service/app.service';

@Component({
    selector: 'imobzi-fab-button',
    templateUrl: './fab-button.component.html',
    styleUrls: ['./fab-button.component.scss'],
    animations: fabButtonAnimations
})
export class FabButtonComponent implements OnInit, OnChanges {

    @Input() parameters: Parameters;
    fabButtons = [
        {
            iconType: 'fab',
            icon: 'whatsapp'
        },
        {
            iconType: 'far',
            icon: 'envelope'
        }
    ];
    buttons = [];
    fabTogglerState = 'inactive';
    public clientBucketPath: string = this.appService.clientBucketPath();
    public layoutBucketPath: string = this.appService.layoutBucketPath();
    public isCardVisible: boolean = true;
    public isVisible: boolean = false;
    public online: boolean;

    constructor(
        public library: FaIconLibrary,
        private dialog: MatDialog,
        private appService: AppService
    ) {
        library.addIcons(faWhatsapp, faEnvelope);
    }

    ngOnInit() {
        this.isOnline()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.parameters && this.parameters) {
            this.isOnline()
        }
    }

    openDialog(value): void {
        (value !== 'whatsapp')
            ? this.dialog.open(ContactRealEstateDialogComponent, {
                panelClass: 'custom-dialog-container'
            })
            : this.onPhoneClicked();
    }

    showItems() {
        this.fabTogglerState = 'active';
        this.buttons = this.fabButtons;
    }

    hideItems() {
        this.fabTogglerState = 'inactive';
        this.buttons = [];
    }

    onToggleFab() {
        this.buttons.length ? this.hideItems() : this.showItems();
        this.hideCard();
    }

    onPhoneClicked() {
        for (const phone of this.parameters.phone) {
            if (phone.type.toLowerCase() === 'whatsapp') {
                return onPhoneClicked(phone, this.dialog, this.parameters);
            }
        }
    }

    hideCard() {
        this.isCardVisible = false;
        this.isVisible = false;
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        const scrollPosition = window.scrollY;
        this.isVisible = scrollPosition > 200 && this.isCardVisible;
    }

    isOnline() {
        if (this.parameters.open_at == '' || this.parameters.closed_at == '') {
            this.online = true;
        } else {
            const now = new Date();
            const currentTimeInMinutes = now.getHours() * 60 + now.getMinutes();
            const toMinutes = (time: string) => time.split(':').reduce((total, num, idx) =>
                total + Number(num) * (idx === 0 ? 60 : 1), 0);
            this.online = currentTimeInMinutes >= toMinutes(this.parameters.open_at) &&
                currentTimeInMinutes < toMinutes(this.parameters.closed_at);
        }
    }
}
