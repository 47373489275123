import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Parameters, Phone, SiteConfiguration } from 'src/app/interface/parameters';
import { AppService } from 'src/app/service/app.service';
import { PropertyService } from 'src/app/service/property.service';
import { isColorDark, onPhoneClicked } from 'src/app/utils/utils';
@Component({
    selector: 'imobzi-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges {

    @Input() sidenav: MatSidenav;
    @Input() parameters: Parameters;
    @Input() calledFromHome: boolean;
    @Input() siteConfiguration: SiteConfiguration;
    backgroundType: string;
    fontColor: string;
    logoUrl: string;
    internalLogoUrl: string;
    searchButtons: string;
    darkMode: boolean;
    headerType: string;
    isSmallScreen: boolean;
    clientBucketPath: string = this.appService.clientBucketPath();
    layoutBucketPath: string = this.appService.layoutBucketPath();
    phone: Phone;

    private breakpoint: any;

    constructor(
        public appService: AppService,
        private breakpointObserver: BreakpointObserver,
        private propertyService: PropertyService,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.getBreakpoint();
        if (this.siteConfiguration)
            this.setHeaderConfiguration();
        this.setWhatsappPhone();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.siteConfiguration && this.siteConfiguration)
            this.setHeaderConfiguration();
        if (changes.parameters && this.parameters)
            this.setWhatsappPhone();
        if ((changes.calledFromHome || changes.siteConfiguration) && this.siteConfiguration)
            this.ChangeFontColor();
    }

    ngOnDestroy(): void {
        this.breakpoint.unsubscribe();
    }

    private getBreakpoint() {
        this.breakpoint = this.breakpointObserver.observe([
            Breakpoints.Small,
            Breakpoints.XSmall
        ]).subscribe(result => {
            this.isSmallScreen = result.matches;
        });
    }

    public removeScroll() {
        this.propertyService.scroll = false;
    }

    public close() {
        this.sidenav.toggle();
    }

    private setHeaderConfiguration() {
        this.backgroundType = this.siteConfiguration.theme.header_color;
        this.logoUrl = this.siteConfiguration.logo;
        this.internalLogoUrl = this.siteConfiguration.internal_logo;
        this.searchButtons = this.siteConfiguration.search_type;
        this.darkMode = isColorDark(this.siteConfiguration.theme.background_color);
        this.headerType = this.siteConfiguration.header_type;
    }

    ChangeFontColor() {
        if (!this.calledFromHome) {
            this.fontColor = this.darkMode ? 'white' : 'dark';
            return;
        }

        if (this.siteConfiguration.search_type === 'banner' && this.backgroundType === '#ffffff00') {
            this.fontColor = 'dark';
        } else {
            this.fontColor = isColorDark(this.backgroundType) ? 'white' : 'dark';
        }
    }


    setWhatsappPhone() {
        if (this.parameters) {
            for (let phone of this.parameters.phone) {
                if (phone.type.toLowerCase() === 'whatsapp') {
                    this.phone = phone;
                }
            }
        }
    }

    onPhoneClicked(phone: Phone) {
        return onPhoneClicked(phone, this.dialog, this.parameters);
    }
}
