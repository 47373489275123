@if (parameters) {
  <div class="contact-phone-container">
    @for (phone of parameters.phone; track phone) {
      <a class="contact-phone" (click)="onPhoneClicked(phone)"
        target="_blank"
        [title]="phone.number" aria-label="Telefones"
        rel="noopener">
        @if (phone.type && phone.type.toLowerCase().indexOf('whatsapp') >= 0) {
          <fa-icon class="phone-icon" [icon]="['fab', 'whatsapp']"></fa-icon>
        }
        {{phone.number}}
      </a>
    }
  </div>
}
