import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild, HostListener } from '@angular/core';
import { Parameters } from 'src/app/interface/parameters';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel, MAT_EXPANSION_PANEL_DEFAULT_OPTIONS } from '@angular/material/expansion';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { ICityNeighborhoodSearch, LocationGroup } from 'src/app/interface/autocomplete-search';
import { SearchAvailabilityTypes } from 'src/app/interface/property';
import { convertDateStringToFullDate, isNumber, translateAvailability, translateFinality, normalizeSearch } from 'src/app/utils/utils';
import { CalendarAvailabilityDialogComponent } from '../calendar-availability/dialog/calendar-availability-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'imobzi-search-form-mobile',
    templateUrl: './search-form-mobile.component.html',
    styleUrls: ['./search-form-mobile.component.scss'],
    providers: [
        {
            provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
            useValue: {
                expandedHeight: '48px',
                collapsedHeight: '48px'
            }
        }
    ]
})
export class SearchFormMobileComponent implements OnInit, OnDestroy {
    @ViewChild('availabilityPanel', { static: false }) availabilityPanel: MatExpansionPanel;
    @ViewChild('typePanel', { static: false }) typePanel: MatExpansionPanel;
    @ViewChild('searchInput', { static: false }) public inputSearch: ElementRef<HTMLInputElement>;
    @Input() public propertyTypesSearch: SearchAvailabilityTypes;
    @Input() public typeSelected: string;
    @Input() public availabilitySelected: string;
    @Input() public adultsSelected: number;
    @Input() public childrenSelected: number;
    @Input() public startDateSelected: string;
    @Input() public endDateSelected: string;
    @Input() public locationGroups: LocationGroup[];
    @Input() public parameters: Parameters;
    @Input() public searchButtons: string;
    @Output() private selectedLocation = new EventEmitter();
    @Output() private searchAvailability = new EventEmitter();
    @Output() private searchType = new EventEmitter();
    @Output() private searchDates = new EventEmitter();
    @Output() private searchAdults = new EventEmitter();
    @Output() private searchChildren = new EventEmitter();
    @Output() private submitForm = new EventEmitter();

    public destroyInput: Subject<boolean> = new Subject<boolean>();
    public locationGroupOptions: Observable<LocationGroup[]>;
    public open = false;
    public panelOpenState1 = false;
    public panelOpenState2 = false;

    public calendarDates: Array<string> = [];
    public guestCounterVisible = false;

    public labelSuggestion: string;
    private selectOnEnter = false;
    public itemIndex = null;
    public selectOnKeyDown: boolean;
    public tabToSelect: boolean;
    public filteredList: any[] = [];
    public valueLocation: ICityNeighborhoodSearch;
    public searchForm: UntypedFormGroup = this.formBuilder.group({
        locationGroup: '',
    });
    private dialogRef: MatDialogRef<CalendarAvailabilityDialogComponent>;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private dialog: MatDialog,
        protected changeDetectorRef: ChangeDetectorRef,
    ) { }

    @HostBinding('class.dropdownVisible') dropdownVisible = false;

    ngOnInit() {
        this.locationGroupOptions = this.searchForm.get('locationGroup').valueChanges
            .pipe(
                debounceTime(300),
                startWith(''),
                map(value => this.filterLocation(value))
            );
    }

    ngOnDestroy(): void {
        this.destroyInput.unsubscribe();
    }

    public showDropdownList() {
        this.open = true;
        this.dropdownVisible = true;
        const style = getComputedStyle(document.body);
        document.documentElement.style.setProperty('--body-overflow', 'hidden');
        this.inputSearch.nativeElement.scrollIntoView(true);
        setTimeout(() => {
            document.documentElement.style.setProperty('--body-overflow', 'auto');
        }, 1000);
    }

    public hideDropdownList() {
        this.open = false;
        this.selectOnEnter = false;
        this.dropdownVisible = false;
    }

    public showCalendar() {
        this.openRequestPropertyDialog();
    }

    private openRequestPropertyDialog(): void {
        this.dialogRef = this.dialog.open(CalendarAvailabilityDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: {
                startDateSelected: this.startDateSelected,
                endDateSelected: this.endDateSelected,
            }
        });

        this.dialogRef.componentInstance.searchDates.subscribe((date) => {
            this.onSearchDates(date);
        });

    }

    public showshowGuestsCounter() {
        (this.guestCounterVisible)
            ? this.guestCounterVisible = false
            : this.guestCounterVisible = true;
    }

    private filterLocation(value: string): LocationGroup[] {
        const normalizedValue = normalizeSearch(value);

        if (value) {
            return this.locationGroups
                .map(group => ({
                    category: group.category, label: group.label.filter(label =>
                        normalizeSearch(label).includes(normalizedValue))
                }))
                .filter(group => group.label.length > 0);
        }
        return this.locationGroups;
    }

    public onSearchAvailability(availability: string) {
        this.searchAvailability.emit(availability);
        this.availabilitySelected = availability;
        this.closePanel();
    }

    public onSearchType(type: string) {
        this.searchType.emit(type);
        this.typeSelected = type;
        this.closePanel();
    }

    public onSearchDates(date: Array<string>) {
        this.calendarDates[0] = date[0];
        this.calendarDates[1] = date[1];

        this.searchDates.emit(this.calendarDates);
    }

    public onSearchAdults = (adults: number) => this.searchAdults.emit(adults);

    public onSearchChildren = (adults: number) => this.searchChildren.emit(adults);

    public translateAvailability(availability: string) {
        return translateAvailability(availability);
    }

    public convertDateStringToFullDate(dateString: string, dateFormat: string) {
        return convertDateStringToFullDate(dateString, dateFormat);
    }

    public closePanel() {
        this.typePanel.close();
        this.availabilityPanel.close();
    }

    public findItemFromSelectValue(selectText: string): any {
        const matchingItems = this.filteredList.filter((item) => item.value === selectText);
        return matchingItems.length ? matchingItems[0] : null;
    }

    public selectOne(category: string, label: string) {
        if (category !== undefined && label !== undefined) {
            this.valueLocation = {
                category,
                label
            };
            this.onSelectedLocation(this.valueLocation);
        }

        this.hideDropdownList();
    }

    public reloadListInDelay(evt: any) {
        const keyword = evt.target.value;
        this.onInput(keyword);
    }

    public onInput(value: any) {
        if (isNumber(value)) {
            this.hideDropdownList();
        }
        if (!this.dropdownVisible) {
            this.dropdownVisible = true;
        }
    }

    public onSubmitForm = () => this.submitForm.emit(this.searchForm.get('locationGroup').value);

    public onSelectedLocation = (value: ICityNeighborhoodSearch) => this.selectedLocation.emit(value);

    public translateFinality(finality: string) {
        return translateFinality(finality);
    }

}
