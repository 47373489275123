<section id="financing" class="container container-margin">
    <div class="financing-container">

        @if (financingSection.title) {
            <imobzi-section-title
                [title]="financingSection.title"
                [subtitle]="financingSection.subtitle"/>
        }

        <div class="financing-sliders">
            <div class="financing-price-value">
                <h3>Preço do Imóvel</h3>
                <h1>{{ priceValue | currency:'BRL' : 'symbol' : '1.0-0' }}</h1>

                <mat-slider
                    [max]="priceValueMax"
                    [min]="priceValueMin"
                    [step]="50000"
                    #propertyPriceSlide>
                    <input matSliderThumb [(ngModel)]="priceValue" (input)="updatePrice({source: propertyPriceThumbSlide, parent: propertyPriceSlide, value: propertyPriceThumbSlide.value})" #propertyPriceThumbSlide="matSliderThumb" (change)="getSimulatorCount()" />
                </mat-slider>
            </div>

            <div class="financing-down-payment">
                <h3>Entrada</h3>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <h1>{{ downPayment | percent }} </h1>
                    <h3 style="margin-left: 10px;">({{ downPaymentValue | currency:'BRL' : 'symbol' : '1.0-0'}})</h3>
                </div>

                <mat-slider
                    [max]="0.9"
                    [min]="0.1"
                    [step]="0.1"
                    #entranceSlide>
                    <input matSliderThumb [(ngModel)]="downPayment" (input)="updateDownPayment({source: entranceThumbSlide, parent: entranceSlide, value: entranceThumbSlide.value})" #entranceThumbSlide="matSliderThumb" />
                </mat-slider>
            </div>
            <div class="financing-interest-rate">
                <h3>Taxa de Juros</h3>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <h1>{{ interestRate | percent }}</h1>
                    <h3 style="margin-left: 10px;">/ano</h3>
                </div>

                <mat-slider
                    [max]="0.12"
                    [min]="0.03"
                    [step]="0.01"
                    #interestRateSlide>
                    <input matSliderThumb [(ngModel)]="interestRate" (input)="updateInterestRate({source: interestRateThumbSlide, parent: interestRateSlide, value: interestRateThumbSlide.value})" #interestRateThumbSlide="matSliderThumb" />
                </mat-slider>
            </div>
            <div class="financing-financing-time">
                <h3>Tempo de Financiamento</h3>
                <h1>{{ financingTime + ((financingTime <= 1) ?' ano' :' anos') }}</h1>

                <mat-slider
                    [max]="35"
                    [min]="5"
                    [step]="1"
                    #financingTimeSlide>
                    <input matSliderThumb [(ngModel)]="financingTime" (input)="updateFinancingTime({source: financingTimeThumbSlide, parent: financingTimeSlide, value: financingTimeThumbSlide.value})" #financingTimeThumbSlide="matSliderThumb" />
                </mat-slider>
            </div>
        </div>

        <div class="financing-chart">
            <div class="chart-container">
                @if (isBrowser) {
                <canvas class="canvas-chart"
                    baseChart
                    [data]="doughnutChartData"
                    [type]="doughnutChartType"
                    [options]="doughnutChartOptions">
                </canvas>
                }
            </div>

            <div class="chart_text">
                <h2>Valor da 1ª parcela</h2>
                <h1 class="display-h2">{{ valueInstallments | currency:'BRL' : 'symbol' : '1.0-0' }} <span>/mês</span></h1>
                <h4 class="value_minimum_income">Renda mínima ({{ valueMinimumIncome | currency:'BRL' : 'symbol' : '1.0-0' }})</h4>
                <h4 class="chart_amortization">Amortização ({{ valueAmortization | currency:'BRL':'symbol': '1.0-0' }} por mês)</h4>
                <h4 class="chart_interest_rate">Juros ({{ valueInterestRate | currency:'BRL' : 'symbol' : '1.0-0' }} por mês)</h4>
                <h4 class="value_insurance_rate">Seguros & Taxas ({{ valueInsuranceRate | currency:'BRL' : 'symbol' : '1.0-0' }})</h4>
            </div>
            <div class="chart_out_text" fxLayout="column" fxLayoutAlign="start center">
                <h3 class="chart_h3">Encontramos {{ propertyCount }} <br> {{ propertyCount > 1 ? ' imóveis' : ' imóvel'}} no seu perfil</h3>

                <a class="btn-financing col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                    (click)="onCloseModal()"
                    [class.btn-disabled-1]="propertyCount < 1"
                    [routerLink]="nameUrl"
                    [queryParams]="queryParams">
                    {{'Ver os Imóveis' | uppercase}}
                </a>
            </div>
        </div>
    </div>
</section>
