@if (!isSmallScreen) {
  <imobzi-calendar
    [startDateSelected]="startDateSelected"
    [endDateSelected]="endDateSelected"
    [calendarAvailability]="calendarAvailability"
    [singleCalendar]="singleCalendar"
    [propertyCalendar]="propertyCalendar"
    [showBorder]="showBorder"
    (searchDates)="onSearchDates($event)">
  </imobzi-calendar>
}