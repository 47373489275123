
@if (showDateVisit) {
  @if (!nextButton || isSmallScreen) {
    <imobzi-date-visit
      (day)="onDayChange($event)"
      (schedule)="onScheduleChange($event)"
      (nextButton)="onNextChange($event)">
    </imobzi-date-visit>
  }
}

@if (formType === 'reserve' || nextButton || isSmallScreen) {
  <imobzi-step-form
    [formContent]="formContent"
    [buttonTitle]="buttonTitle"
    [property]="property"
    [dates]="dates"
    [loading]="loading"
    [class.reserve-form]="!showDateVisit"
    (formSubmit)="onFormSubmit($event)"
    (exportDates)="calculateIntervalDates($event)">
  </imobzi-step-form>
}
