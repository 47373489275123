<imobzi-button-close-modal id="close-gallery-modal" (closeChange)='closeModal($event)'></imobzi-button-close-modal>

<section id="gallery-modal" [class.fullModal]="!displayMidia" class="mat-dialog-content">
    <swiper-container class="gallery-container" appSwiper [config]="galleryTop" thumbs-swiper=".thumbs-container" #galleryContainer (slidechange)="getImgSize($event)">
        @for (midia of data.path.midia; track midia; let index = $index) {
            <swiper-slide class="gallery-slide">
                @if (midia.category === 'photos') {
                    <div class="gallery-image img-responsive img-rounded image-container-responsive">
                        <img id="img-{{index}}"
                        class="gallery-image img-responsive img-rounded"
                        src="{{ midia.url + '=s0' }}"
                        alt="{{ midia.description }}"
                        title="{{ midia.description }}">
                    </div>
                } @else {
                    <iframe class="gallery-video img-responsive img-rounded"
                        [src]="sanitizeUrl(midia.url)"
                        frameborder="0"
                        autoplay
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen>
                    </iframe>
                }
            </swiper-slide>
        }
        <div class="gallery-pagination"></div>
    </swiper-container>

    <swiper-container appSwiper [config]="galleryThumbs" class="thumbs-container" #thumbsContainer [class.displayNone]="displayMidia">
        @for (midia of data.path.midia; track midia) {
            <swiper-slide class="thumbs-slide" fxLayout="row" fxLayoutAlign="space-evenly center">
                @if (midia.category === 'photos') {
                    <img class="thumbs-image img-responsive img-rounded" src="{{ midia.url + '=s260' }}" alt="{{ midia.description }}" title="{{ midia.description }}">
                }
            </swiper-slide>
        }
    </swiper-container>

    <div class="actions-container" [class.displayNone]="displayAction" fxLayout="row" fxLayoutAlign="space-between center">
        <button (click)="swipePrev()" class="btn-icon btn-light-1 btn-lg-64 swiper-prev shadow" type="button" aria-label="Button Previous Page" mat-icon-button>
            <mat-icon class="material-icons-round" aria-label="Previous">
                chevron_left
            </mat-icon>
        </button>

        <button (click)="swipeNext()" class="btn-icon btn-light-1 btn-lg-64 swiper-next shadow" type="button" aria-label="Button Next Page" mat-icon-button>
            <mat-icon class="material-icons-round" aria-label="Next">
                chevron_right
            </mat-icon>
        </button>
    </div>
</section>
