import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgChartsModule } from 'ng2-charts';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxSelectModule } from 'ngx-select-ex';
import { DirectiveModule } from '../directive/directive.module';
import { PipesModule } from '../pipes/pipes.module';
import { AppService } from '../service/app.service';
import { ParametersService } from '../service/parameters.service';
import { AreaClienteComponent } from './area-cliente/area-cliente.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb';
import { ButtonCloseModalComponent } from './button-close-modal/button-close-modal.component';
import { AddressComponent } from './contact/address/address.component';
import { ContactComponent } from './contact/contact.component';
import { EmailComponent } from './contact/email/email.component';
import { PhoneComponent } from './contact/phone/phone.component';
import { SocialNetworkComponent } from './contact/social-network/social-network.component';
import { FabButtonComponent } from './fab-button/fab-button.component';
import { FinancingSimulatorComponent } from './financing-simulator/financing-simulator.component';
import { FooterComponent } from './footer/footer.component';
import { ProgressSpinnerComponent } from './forms/progress-spinner/progress-spinner.component';
import { StepFormComponent } from './forms/step-form/step-form.component';
import { SuccessFormComponent } from './forms/success-form/success-form.component';
import { HeaderComponent } from './header/header.component';
import { SearchBarComponent } from './header/search-bar/search-bar.component';
import { SearchFormMobileComponent } from './header/search-bar/search-form/search-form-mobile.component';
import { SearchFormComponent } from './header/search-bar/search-form/search-form.component';
import { MenuListComponent } from './menu/menu-list/menu-list.component';
import { MenuComponent } from './menu/menu.component';
import { ContactBrokerModalComponent } from './modal/contact-broker/contact-broker-modal.component';
import { ScheduleVisitMobileComponent } from './modal/contact-broker/schedule-visit/schedule-visit-mobile.component';
import { ScheduleVisitComponent } from './modal/contact-broker/schedule-visit/schedule-visit.component';
import { StepFirstComponent } from './modal/contact-broker/schedule-visit/step-first/step-first.component';
import { DateVisitComponent } from './modal/contact-broker/schedule-visit/step-second/date-visit/date-visit.component';
import { StepSecondComponent } from './modal/contact-broker/schedule-visit/step-second/step-second.component';
import { TalkBrokerDialogComponent } from './modal/contact-broker/talk-broker/talk-broker-dialog/talk-broker-dialog.component';
import { TalkBrokerMobileComponent } from './modal/contact-broker/talk-broker/talk-broker-mobile.component';
import { TalkBrokerComponent } from './modal/contact-broker/talk-broker/talk-broker.component';
import { ContactRealEstateDialogComponent } from './modal/contact-real-estate/contact-real-estate-dialog.component';
import { FinancingSimulatorDialogComponent } from './modal/financing-simulator/financing-simulator-dialog.component';
import { SectionTitleComponent } from './section-title/section-title.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { GuestsCounterComponent } from './header/search-bar/guests-counter/guests-counter.component';
import { CalendarAvailabilityComponent } from './header/search-bar/calendar-availability/calendar-availability.component';
import { CalendarComponent } from './header/search-bar/calendar-availability/calendar/calendar.component';
import { MatCardModule } from '@angular/material/card';
import { GuestsCounterMobileComponent } from './header/search-bar/guests-counter/guests-counter-mobile.component';
import { CalendarAvailabilityDialogComponent } from './header/search-bar/calendar-availability/dialog/calendar-availability-dialog.component';
import { CalendarMobileComponent } from './header/search-bar/calendar-availability/calendar/calendar-mobile.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PhoneNumbersComponent } from './phone-numbers/phone-numbers.component';
import { WhatsappDialogComponent } from './modal/whatsapp-dialog/whatsapp-dialog.component';
import { PopupComponent } from './popup/popup.component';
import { PhotoErrorComponent } from './photo-error/photo-error.component';
import { PhotoNotFoundComponent } from './photo-not-found/photo-not-found.component';
import { CustomButtonComponent } from './custom-button/custom-button.component';
import { LeafletComponent } from './leaflet/leaflet.component';
import { PopoverComponent } from './popover/popover.component';
import { HeaderMenuComponent } from './header/header-menu/header-menu.component';
import { MultiMediaDialogComponent } from './modal/multi-media-dialog/multi-media-dialog.component';
import { TagsComponent } from './tags/tags.component';

@NgModule({
    declarations: [
        AreaClienteComponent,
        AddressComponent,
        BreadcrumbComponent,
        EmailComponent,
        PhoneComponent,
        SocialNetworkComponent,
        ContactComponent,
        ContactRealEstateDialogComponent,
        FabButtonComponent,
        FinancingSimulatorComponent,
        FinancingSimulatorDialogComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        SectionTitleComponent,
        MenuListComponent,
        ButtonCloseModalComponent,
        SuccessFormComponent,
        StepFormComponent,
        ProgressSpinnerComponent,
        SearchBarComponent,
        SearchFormComponent,
        SearchFormMobileComponent,
        ContactBrokerModalComponent,
        TalkBrokerMobileComponent,
        TalkBrokerComponent,
        ScheduleVisitComponent,
        StepFirstComponent,
        StepSecondComponent,
        ScheduleVisitMobileComponent,
        DateVisitComponent,
        TalkBrokerDialogComponent,
        GuestsCounterComponent,
        GuestsCounterMobileComponent,
        CalendarAvailabilityComponent,
        CalendarComponent,
        CalendarMobileComponent,
        CalendarAvailabilityDialogComponent,
        PrivacyPolicyComponent,
        PhoneNumbersComponent,
        WhatsappDialogComponent,
        PopupComponent,
        PhotoErrorComponent,
        PhotoNotFoundComponent,
        CustomButtonComponent,
        LeafletComponent,
        PopoverComponent,
        HeaderMenuComponent,
        MultiMediaDialogComponent,
        TagsComponent
    ],
    imports: [
        CommonModule,
        LayoutModule,
        RouterModule,
        DirectiveModule,
        PipesModule,
        ReactiveFormsModule,
        FormsModule,
        FlexLayoutModule,
        FileUploadModule,
        NgChartsModule,
        MatSliderModule,
        FontAwesomeModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatSidenavModule,
        MatListModule,
        MatDialogModule,
        MatStepperModule,
        MatExpansionModule,
        MatSelectModule,
        MatButtonToggleModule,
        MatAutocompleteModule,
        NgxSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        NgxMaskDirective,
        NgxMaskPipe,
        NgOptimizedImage
    ],
    exports: [
        GuestsCounterMobileComponent,
        CommonModule,
        PrivacyPolicyComponent,
        FinancingSimulatorComponent,
        FinancingSimulatorDialogComponent,
        AreaClienteComponent,
        AddressComponent,
        EmailComponent,
        PhoneComponent,
        SocialNetworkComponent,
        HeaderComponent,
        MenuComponent,
        ContactComponent,
        FabButtonComponent,
        ContactRealEstateDialogComponent,
        FooterComponent,
        SectionTitleComponent,
        BreadcrumbComponent,
        ButtonCloseModalComponent,
        SuccessFormComponent,
        StepFormComponent,
        ProgressSpinnerComponent,
        ContactBrokerModalComponent,
        GuestsCounterComponent,
        CalendarAvailabilityComponent,
        CalendarComponent,
        CalendarMobileComponent,
        CalendarAvailabilityDialogComponent,
        PhoneNumbersComponent,
        PhotoErrorComponent,
        PhotoNotFoundComponent,
        LeafletComponent,
        PopoverComponent,
        MultiMediaDialogComponent,
        TagsComponent
    ],
    providers: [
        AppService,
        ParametersService,
        provideNgxMask(),
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class ComponentsModule { }
