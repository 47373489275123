<section id="search-mobile">
  @if (propertyTypesSearch) {
    <form
      class="form-search col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
      [formGroup]="searchForm"
      (ngSubmit)="onSubmitForm()">
      <div class="row group-search">
        <div [class.vacation-rental-container-search]="availabilitySelected === 'vacation_rental'" class="container-search">
          <mat-accordion class="col-xs-12 col-sm-12 col-md-12 item-1"
            [class.open]="panelOpenState1">
            <mat-expansion-panel #availabilityPanel="matExpansionPanel"
              [class.border-primary-2]="panelOpenState1"
              (opened)="panelOpenState1 = true"
              (closed)="panelOpenState1 = false">
              <mat-expansion-panel-header>
                <mat-panel-title class="h3">
                  {{ translateAvailability(availabilitySelected) || "Disponibilidade" }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ul>
                @for (finality of propertyTypesSearch | objectgroups; track finality) {
                  @if (availabilitySelected !== finality) {
                    <li class="h3"
                      (click)="onSearchAvailability(finality)">
                      {{ translateAvailability(finality) }}
                    </li>
                  }
                }
              </ul>
            </mat-expansion-panel>
          </mat-accordion>
          @if (availabilitySelected !== 'vacation_rental') {
            <mat-accordion class="col-xs-12 col-sm-12 col-md-12 item-2"
              [class.open]="panelOpenState2">
              <mat-expansion-panel #typePanel="matExpansionPanel"
                [class.border-primary-2]="panelOpenState2"
                (opened)="panelOpenState2 = true"
                (closed)="panelOpenState2 = false">
                <mat-expansion-panel-header>
                  <mat-panel-title class="h3">
                    {{ typeSelected || "Tipo" }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ul>
                  @for (finalitys of propertyTypesSearch[availabilitySelected]; track finalitys) {
                    <li class="h3 property-finality bold">
                      <a>{{ translateFinality(finalitys.property_finality) }}</a>
                    </li>
                    @for (type of finalitys.types; track type) {
                      @if (typeSelected !== type.property_type) {
                        <li
                          (click)="onSearchType(type.property_type)"
                          (click)="closePanel()"
                          class="h3 property-type">
                          {{ type.property_type }}
                        </li>
                      }
                    }
                  }
                </ul>
              </mat-expansion-panel>
            </mat-accordion>
          }
        </div>
        <div class="group-input_autocomplete col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <input #searchInput id="searchInput" class="h3 color-input-color padding-10 input-search col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            placeholder="Cidade, bairro, condomínio ou código"
            autocomplete="off"
            formControlName="locationGroup"
            [class.open]="open"
            (input)="reloadListInDelay($event)"
            (blur)="hideDropdownList()"
            (focus)="showDropdownList()"
            (click)="$event.stopPropagation()"
            type="text">
          @if (dropdownVisible) {
            <div class="option-group col-md-12 col-lg-12 col-xl-12">
              <ul>
                <div id="overflow" class="overflow-scroll">
                  @for (suggestion of locationGroupOptions | async; track suggestion; let index = $index) {
                    @if (suggestion.label.length > 0) {
                      <li class="option-category bold h3 bold">
                        {{suggestion.category}}
                      </li>
                    }
                    @for (label of suggestion.label; track i; let i = $index) {
                      <li
                        class="option-item h3"
                        [ngClass]="{selected: i === itemIndex}"
                        (mousedown)="selectOne(suggestion.category, label)">
                        {{ label }}
                      </li>
                    }
                  }
                </div>
              </ul>
            </div>
          }
        </div>
        @if (availabilitySelected === 'vacation_rental') {
          <div class="vacation-rental-option-search col-xs-12 col-sm-12 col-md-12">
            <div (click)="showCalendar()" class="h3 clickable-option">
              <div>
                @if (!startDateSelected && !endDateSelected) {
                  <span>Chegada e Saída</span>
                }
                @if (startDateSelected && endDateSelected) {
                  <span>{{ convertDateStringToFullDate(startDateSelected, 'abbreviatedDate') + ' à ' + convertDateStringToFullDate(endDateSelected, 'abbreviatedDate') }}</span>
                }
              </div>
              <mat-icon>expand_more</mat-icon>
            </div>
          </div>
          <div class="vacation-rental-option-search col-xs-12 col-sm-12 col-md-12">
            <div (click)="showshowGuestsCounter()" class="h3 clickable-option">
              <div>
                @if (!adultsSelected && !childrenSelected) {
                  <span>Hóspedes</span>
                }
                @if (adultsSelected + childrenSelected > 0) {
                  <span>{{ adultsSelected + childrenSelected }} {{(adultsSelected + childrenSelected > 1) ? 'hóspedes': 'hóspede'}}</span>
                }
              </div>
              @if (!guestCounterVisible) {
                <mat-icon>expand_more</mat-icon>
              }
              @if (guestCounterVisible) {
                <mat-icon>close</mat-icon>
              }
            </div>
          </div>
        }
        <button [class.btn-submit-search-mobile]="searchButtons === 'banner'" class="col-xs-12 col-sm-12 btn-submit" id="submit-button" type="submit">Buscar</button>
        @if (parameters && parameters.site_home_button.active && parameters.site_home_button.button_type === 'property_filter') {
          <button
            type="button"
            class="col-xs-8 col-sm-8 btn-custom">
            <imobzi-custom-button
              [buttonHome]="parameters.site_home_button">
            </imobzi-custom-button>
          </button>
        }
      </div>
    </form>
  }
</section>

@if (guestCounterVisible) {
  <imobzi-guests-counter-mobile
    [adultsSelected]="adultsSelected"
    [childrenSelected]="childrenSelected"
    (searchAdults)="onSearchAdults($event)"
    (searchChildren)="onSearchChildren($event)"
    (closeCounter)="guestCounterVisible = false"
    >
  </imobzi-guests-counter-mobile>
}

@if (panelOpenState1 === true || panelOpenState2 === true) {
  <div class="accordion-dismiss" (click)="closePanel()"></div>
}
