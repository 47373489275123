<div id="step-form" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
    fxLayout="column" fxLayoutAlign="center center">

    @for (form of masterForm; track index; let index=$index) {
        @if (index === activeStepIndex || masterForm.length === 1) {
            <form
                class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-12"
                [formGroup]="masterForm[index]"
                [class]="'step-' + activeStepIndex">

                @for (field of formFields[index]; track field) {
                    <div 
                        [ngSwitch]="currentFormContent[index][field].type"
                        class="relative {{currentFormContent[index][field].class}}"
                    >
                        @if (
                            (
                                masterForm[index].get(field).dirty 
                                || masterForm[index].get(field).touched
                            ) && masterForm[index].get(field).invalid 
                            && masterForm[index].get(field).errors
                        ) {<p class="validation-error">{{ getValidationMessage(index, field) }}</p>}

                        @switch (currentFormContent[index][field].type) {
                            @case ('textarea') {
                                <textarea 
                                    class="textarea col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    [class]="field"
                                    [formControlName]="field"
                                    [class.textarea-error]="(masterForm[index].get(field).dirty || masterForm[index].get(field).touched) && masterForm[index].get(field).invalid && masterForm[index].get(field).errors"
                                    [placeholder]="currentFormContent[index][field].placeholder">
                                </textarea>
                            }
                            @case ('select') {
                                <mat-accordion 
                                    class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    [class.select-error]="(
                                        (masterForm[index].get(field).dirty || masterForm[index].get(field).touched) 
                                        && masterForm[index].get(field).invalid 
                                        && masterForm[index].get(field).errors
                                    )"
                                >
                                    <mat-expansion-panel 
                                        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                        #mapanel="matExpansionPanel">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title class="h4">
                                                <input type="radio" [formControlName]="field" [id]="field" [value]="masterForm[index].get(field).value">
                                                {{masterForm[index].get(field).value || currentFormContent[index][field].placeholder}}
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        @if (currentFormContent[index][field]['grouped']) {
                                            <ul>
                                                @for (option of currentFormContent[index][field].options; track option.finality) {
                                                    <li class="finality bold">{{option.finality}}</li>
                                                    @for (type of option.types; track type) {
                                                        <li
                                                            class="type"
                                                            [value]="type"
                                                            (click)="getSelectValue(index, field, type)"
                                                            (click)="emitSelectValue(index, 'finality', option.finality, currentFormContent[index][field].emitSelected)"
                                                            (click)="mapanel.close()">
                                                            <a>{{ type }}</a>
                                                        </li>
                                                    }
                                                }
                                            </ul>
                                        } @else {
                                            <ul>
                                                @for (option of currentFormContent[index][field].options; track option) {
                                                    <li 
                                                        [value]="option.name"
                                                        (click)="getSelectValue(index, field, option.name)"
                                                        (click)="emitSelectValue(index, field, option.name, currentFormContent[index][field].emitSelected)"
                                                        (click)="mapanel.close()">
                                                        <a>{{ option.name }}</a>
                                                    </li>
                                                }
                                            </ul>
                                        }
                                    </mat-expansion-panel>
                                </mat-accordion>
                            }
                            @case ('calendar') {
                                <input 
                                    class="input h4 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" 
                                    [class]="field"
                                    type="text" [formControlName]="field"
                                    [placeholder]="currentFormContent[index][field].placeholder"
                                    (click)="showCalendar(field)"
                                    [readonly]="true"
                                    [ngModel]="(field === 'start') ? calendarDatesExtensive[0] : calendarDatesExtensive[1]" 
                                    (ngModelChange)="(field === 'start') ? calendarDatesExtensive[0] : calendarDatesExtensive[1] = $event"
                                    [class.input-error]="(
                                        (masterForm[index].get(field).dirty || masterForm[index].get(field).touched) 
                                        && masterForm[index].get(field).invalid 
                                        && masterForm[index].get(field).errors
                                    )"
                                >
                                @if (calendarVisible === field) {
                                    <imobzi-calendar-availability 
                                        [startDateSelected]="calendarDates[0]"
                                        [endDateSelected]="calendarDates[1]"
                                        [calendarAvailability]="field"
                                        [singleCalendar]="singleCalendar"
                                        [propertyCalendar]="property.calendar"
                                        (searchDates)="onChangedDate($event)">
                                    </imobzi-calendar-availability>
                                }
                            }
                            @default {
                                @if (currentFormContent[index][field].mask) {
                                    <input 
                                        class="input h4 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" 
                                        [class]="field"
                                        [type]="'text'" [formControlName]="field"
                                        [placeholder]="currentFormContent[index][field].placeholder"
                                        [mask]="currentFormContent[index][field].mask"
                                        [dropSpecialCharacters]="currentFormContent[index][field].hasOwnProperty('dropSpecialCharacters') ? currentFormContent[index][field]['dropSpecialCharacters'] : true"
                                        thousandSeparator="."
                                        [class.input-error]="(
                                            (masterForm[index].get(field).dirty || masterForm[index].get(field).touched) 
                                            && masterForm[index].get(field).invalid 
                                            && masterForm[index].get(field).errors
                                        )"
                                    />
                                } @else {
                                    <input 
                                        class="input h4 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" [class]="field"
                                        [type]="currentFormContent[index][field].type" [formControlName]="field"
                                        [placeholder]="currentFormContent[index][field].placeholder"
                                        (change)="onChangeValue($event)"
                                        id={{field}}
                                        [class.input-error]="(
                                            (masterForm[index].get(field).dirty || masterForm[index].get(field).touched) 
                                            && masterForm[index].get(field).invalid 
                                            && masterForm[index].get(field).errors
                                        )"
                                    />
                                }
                            }
                        }
                    </div>
                }
    
                @if (stepItems.length > 1 && activeStepIndex === stepItems.length - 1) {
                    <div class="files-container gd-xs-2-11 gd-sm-2-11 gd-md-5-8 gd-lg-5-8 gd-xl-5-8">
                        <div class="files-content grid-xs-12 grid-sm-4 grid-md-4 grid-lg-4 grid-xl-4">
                            <img class="gd-xs-1-12 gd-sm-2-3 gd-md-2-3 gd-lg-2-3 gd-xl-2-3" [src]="layoutBucketPath + 'picture.svg'" alt="">
                            <button class="gd-xs-2-11 gd-sm-2-3 gd-md-2-3 gd-lg-2-3 gd-xl-2-3 btn btn-primary-1 btn-md" aria-label="Enviar Imagens" (click)="openUploader();">Enviar
                                Imagens</button>
                            <input id="uploader" type="file" ng2FileSelect [uploader]="uploader" multiple style="display:none;" />
                        </div>
        
                        <p class="margin-top-8">* Máximo de 50 Arquivos com até 30mb</p>
        
                        <div class="itens-anexo grid-6 margin-top-25" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
                            @for (item of uploader.queue; track item.file.name) {
                                <div class="btn btn-secondary-2 btn-md padding-10 margin-bottom-10"
                                    fxLayout="row" fxLayoutAlign="space-evenly center">
                                    <label [title]="item?.file?.name">{{ item?.file?.name.slice(0, 10) + '...' }}</label>
                                    <a class="anexo_remover" aria-label="Remover Anexos" title="remover" (click)="item.remove();">x</a>
                                </div>
                            }
                        </div>
                    </div>
                }
    
                <div [class.loading-container]="loading" class="button-container gd-xs-1-12 gd-sm-1-12 gd-md-1-12 gd-lg-1-12 gd-xl-1-12 grid-12">
                    @if (stepItems.length > 1 && !loading) {
                        <button class="gd-xs-1-5 gd-sm-1-5 gd-md-8-9 gd-lg-8-9 gd-xl-8-9 btn btn-disabled-2 btn-md" type="button"
                            [class.disabled]="activeStepIndex < 1 || activeStepIndex > 3"
                            (click)="goToStep('prev')">Voltar
                        </button>
                    }
                    @if (stepItems.length > 1 && activeStepIndex < 3 && !loading) {
                        <button class="gd-xs-8-12 gd-sm-8-12 gd-md-10-11 gd-lg-10-11 gd-xl-10-11 btn btn-primary-1 btn-md" type="button"
                            [disabled]="masterForm[index].invalid"
                            [class.btn-disabled-1]="masterForm[index].invalid"
                            (click)="goToStep('next')">Avançar
                        </button>
                    }
                    @if (activeStepIndex === stepItems.length - 1  && !loading) {
                        <button class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 btn btn-md"
                            [class]="(buttonClass && !masterForm[index].invalid) ? buttonClass : 'btn-primary-1'"
                            type="button"
                            [disabled]="masterForm[index].invalid"
                            [class.step-request]="stepItems.length <= 1"
                            [class.step-advertise]="stepItems.length > 1"
                            [class.btn-disabled-1]="masterForm[index].invalid"
                            (click)="goToStep('next')"
                            (click)="onFormSubmit()">{{ buttonTitle || 'Enviar' }}
                        </button>
                    }
                    @if (loading) { <div class="loader"></div> }
                </div>
            </form>
        }
    }
</div>
@if (calendarVisible !== '') { <div class="accordion-dismiss" (click)="closeCalendar()"></div> }

