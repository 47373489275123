import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Database } from '../interface/parameters';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AppInitService {
    private databaseValue: string | null = null;
    private appOrigin: string;
    private tempDatabase: string;

    constructor(
        private http: HttpClient,
        private router: Router,
        @Optional() @Inject('ORIGIN') private injectedOrigin: string,
        @Optional() @Inject('TEMP_DATABASE') private injectedTempDatabase: string
    ) {
        this.appOrigin = this.detectOrigin();
        this.tempDatabase = this.injectedTempDatabase ?? this.getTempDatabaseFromPath();
    }

    private getTempDatabaseFromPath(): string | null {
        if (typeof window !== 'undefined' && this.appOrigin.includes('site.imobz.in')) {
            const tempDb = window.location.pathname.replace('/a/', '');
            return tempDb || null;
        }
        return null;
    }

    private detectOrigin(): string {
        return this.injectedOrigin || (typeof window !== 'undefined' ? window.location.hostname : '');
    }

    private buildHeaders(): HttpHeaders {
        if (this.appOrigin) {
            return new HttpHeaders({
                'Origin': this.appOrigin,
                'Referer': this.appOrigin
            });
        }
        return new HttpHeaders();
    }

    public async loadDatabase(): Promise<void> {
        if (this.tempDatabase) {
            this.databaseValue = this.tempDatabase;
            return;
        }

        try {
            const sanitizedOrigin = this.appOrigin.replace(/^https?:\/\//, '');
            const headers = this.buildHeaders();
            const response = await this.http
                .get<Database>(`https://api2.imobzi.app/v1/site/database/${sanitizedOrigin}`, { headers })
                .pipe(
                    map((response) => response.database),
                    catchError((error) => {
                        console.error('Failed to load database:', error);
                        if (error && error.status === 402) {
                            this.router.navigate(['suspended']);
                        }
                        return of(null);
                    })
                )
                .toPromise();

            if (response) {
                this.databaseValue = response;
            }
        } catch (error) {
            console.error('Error during database loading:', error);
        }
    }

    public getDatabaseString(): string | null {
        return this.databaseValue;
    }
}
