<ul class="header-menu grid-xs-12 grid-sm-12 grid-md-12 grid-lg-12 grid-xl-12">
    <mat-accordion>
        @for (menu of mainMenu.slice(0, 3); track menu.menu_name) {
            <mat-expansion-panel
                [expanded]="expansionMenus[menu.menu_name]">
                <mat-expansion-panel-header
                    [class.expansion-header-light]="fontColor === 'white'"
                    [class.expansion-header-dark]="fontColor === 'dark'">
                    <mat-panel-title class="h3 bold"
                        [class.color-light]="fontColor === 'white'"
                        [class.color-dark]="fontColor === 'dark'">
                        {{ menu.menu_name }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ul class="menu-list" [style.background-color]="calledFromHome ? '#00000030' : '#ffffff'">
                    @for (submenuItem of menu.submenus; track submenuItem.group_item) {
                        @if (submenuItem.group_item !== '') {
                            <li
                                [style.order]="submenuItem.position"
                                class="submenu bold">
                                @if (!submenuItem.new_tab) {
                                    <a class="item_submenu"
                                        [class.color-dark]="!calledFromHome"
                                        [title]="submenuItem.group_item"
                                        [href]="[(submenuItem.url)? submenuItem.url : undefined]">
                                        {{ submenuItem.group_item }}
                                    </a>
                                } @else {
                                    <a class="item_submenu"
                                        [class.color-dark]="!calledFromHome"
                                        [title]="submenuItem.group_item"
                                        (click)="onMenuItemClicked(submenuItem)">
                                        {{ submenuItem.group_item }}
                                    </a>
                                }
                            </li>
                        }
                    }
                </ul>
            </mat-expansion-panel>
        }
    </mat-accordion>
    <li class="menu-button h3 bold" (click)="openDialog()"
        [class.color-light]="fontColor === 'white'"
        [class.color-dark]="fontColor === 'dark'">
        Contato
    </li>
</ul>
