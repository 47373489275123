import { Component, Inject, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PropertyPhoto } from 'src/app/interface/property';
import { GalleryModalComponent } from 'src/app/pages/details/gallery-modal/gallery-modal.component';

@Component({
    selector: 'imobzi-multi-media-dialog',
    templateUrl: './multi-media-dialog.component.html',
    styleUrls: ['./multi-media-dialog.component.scss']
})
export class MultiMediaDialogComponent implements OnInit, OnChanges {
    @ViewChild('swiper', { static: false }) swiper;

    public categories: Array<{ name: string, photoUrl: string }> = [];
    public filteredPhotos: Array<PropertyPhoto> = [];
    public dialogRef: MatDialogRef<GalleryModalComponent>;
    public indexSlider = 0;
    public PhotosByCategory;
    public swiperCategories;


    constructor(
        public dialog: MatDialog,
        public multiDialogRef: MatDialogRef<MultiMediaDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { photos: Array<PropertyPhoto> }
    ) { }

    ngOnInit(): void {
        this.orderCategory();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.swiperCategories = {
            a11y: true,
            direction: 'horizontal',
            slidesPerView: 1,
            keyboard: false,
            mousewheel: false,
            scrollbar: true,
            navigation: true,
            pagination: false,
            initialSlide: 0,
            effect: 'slide',
            spaceBetween: 2,
            autoHeight: false,
            loopedSlides: 2,
            loop: false,
            watchOverflow: true,
        };
    }


    public orderCategory() {
        const categoryMap: Map<string, string> = new Map();

        this.PhotosByCategory = {}
        this.data.photos.forEach(photo => {
            if (!this.PhotosByCategory[photo.category])
                this.PhotosByCategory[photo.category] = [];
            this.PhotosByCategory[photo.category].push(photo);
            if (!categoryMap.has(photo.category)) {
                categoryMap.set(photo.category, photo.url);
            }
        });

        this.categories = Array.from(categoryMap.entries()).map(([name, photoUrl]) => ({ name, photoUrl }));
    }

    public selectCategory(category: string) {
        this.filteredPhotos = this.data.photos.filter(photo => photo.category === category);
        this.scrollToCategory(category);
    }

    public openDialogGallery(midia, index): void {
        this.dialogRef = this.dialog.open(GalleryModalComponent, {
            panelClass: ['custom-dialog-container', 'gallery-dialog-container'],
            data: {
                path: { midia, index }
            },
        });
    }

    public scrollToCategory(category: string) {
        const element = document.getElementById(category);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    closeDialog(value: boolean): void {
        this.multiDialogRef.close();
    }
}
