@if (parameters) {
  <imobzi-menu-list [parameters]="parameters" [sidenav]="sidenav"></imobzi-menu-list>
}
@if (parameters) {
  @if (parameters.client_area) {
    <imobzi-area-cliente class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 area-cliente btn btn-light-1 btn-md"></imobzi-area-cliente>
  }
  @if (parameters.site_home_button.active && parameters.site_home_button.button_type !== 'property_filter') {
    <imobzi-custom-button
      class="margin-top-20 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 area-cliente btn btn-light-1 btn-md"
      [buttonHome]="parameters.site_home_button">
    </imobzi-custom-button>
  }
}
<imobzi-contact [parameters]="parameters"></imobzi-contact>
