import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ICityNeighborhoodSearch, LocationGroup } from 'src/app/interface/autocomplete-search';
import { ICity } from 'src/app/interface/city';
import { INeighborhood } from 'src/app/interface/neighborhood';
import { Parameters, SiteConfiguration } from 'src/app/interface/parameters';
import { SearchAvailabilityTypes } from 'src/app/interface/property';
import { AppService } from 'src/app/service/app.service';
import { PropertyService } from 'src/app/service/property.service';
import { isNumber } from 'src/app/utils/utils';

@Component({
    selector: 'imobzi-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy {

    @Input() clientBucketPath: string;
    @Input() parameters: Parameters;
    @Input() siteConfiguration: SiteConfiguration;

    public propertyTypesSearch: SearchAvailabilityTypes;
    public availabilitySelected: string;
    public typeSelected: string;
    public adultsSelected = 0;
    public childrenSelected = 0;
    public startDateSelected: string;
    public endDateSelected: string;
    public locationGroups: LocationGroup[];
    private suggestions: Array<ICityNeighborhoodSearch> = [];
    private neighborhoodsLabel = [];
    private cityLabel = [];
    private buildingsLabel = [];
    public optionsSearch: string;
    public optionsSearchValue: string;
    private breakpoint: any;
    public saveNeighborhood: string[];
    public saveCity: string[];
    public isVideo = false;
    public landUrl: string;
    public searchButtons: string;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        public appService: AppService,
        private router: Router,
        private propertyService: PropertyService,
        private breakpointObserver: BreakpointObserver,
        @Inject(PLATFORM_ID) private platformId: Object
    ) { }

    ngOnInit() {
        this.getBreakpoint();
        this.getSearchPropertyTypes();
        this.setSearchBarConfiguration();
    }

    ngOnDestroy(): void {
        this.breakpoint.unsubscribe();
    }

    private getBreakpoint() {
        this.breakpoint = this.breakpointObserver.observe([
            Breakpoints.HandsetLandscape
        ]).subscribe(result => {
            (result.matches)
                ? this.setBannerSizeMob('140vh', '140vh')
                : this.setBannerSizeMob('100vh', '100vh');

            (result.matches && this.breakpointObserver.isMatched('(min-width: 812px) and (max-width: 823px'))
                ? this.setBannerSizeTab('136vh', '146vh')
                : this.setBannerSizeTab('90vh', '95vh');
        });
    }

    private setBannerSizeMob(banner: string, img: string) {
        if (isPlatformBrowser(this.platformId)) {
            document.documentElement.style.setProperty('--banner-mob', banner);
            document.documentElement.style.setProperty('--img-mob', img);
        } else {
            this.renderer.setStyle(this.el.nativeElement, '--banner-mob', banner);
            this.renderer.setStyle(this.el.nativeElement, '--img-mob', img);
        }
    }

    private setBannerSizeTab(banner: string, img: string) {
        if (isPlatformBrowser(this.platformId)) {
            document.documentElement.style.setProperty('--banner-tab', banner);
            document.documentElement.style.setProperty('--img-tab', img);
        } else {
            this.renderer.setStyle(this.el.nativeElement, '--banner-tab', banner);
            this.renderer.setStyle(this.el.nativeElement, '--img-tab', img);
        }
    }

    private getSearchPropertyTypes() {
        this.propertyService.searchPropertyTypes().subscribe(propertyTypesSearch => {
            this.propertyService.propertyFields = propertyTypesSearch;

            if (propertyTypesSearch && Object.keys(propertyTypesSearch).length > 0) {
                this.propertyTypesSearch = propertyTypesSearch;
                (this.propertyTypesSearch.buy && this.propertyTypesSearch.buy.length > 0)
                    ? this.availabilitySelected = 'buy'
                    : this.availabilitySelected = Object.keys(propertyTypesSearch)[0];

                this.setPropertyNeighborhoods();
                this.getFeatures();
            }
        });
    }

    setPropertyNeighborhoods() {
        this.suggestions = [];
        this.saveNeighborhood = [];
        this.saveCity = [];
        this.propertyTypesSearch[this.availabilitySelected].forEach(propertyFinality => {
            propertyFinality.types.forEach(element => {
                if (this.typeSelected) {
                    if (element.property_type === this.typeSelected) {
                        element.neighborhoods.forEach((neighborhood: INeighborhood) => {
                            if (!this.neighborhoodInSuggestions(neighborhood)) {
                                this.neighborhoodAddToSuggestion(neighborhood);
                                this.saveNeighborhood.push(`${neighborhood.name.toLowerCase()} - ${neighborhood.city.name.toLowerCase()}`);
                            }
                            if (!this.cityInSuggestions(neighborhood.city)) {
                                this.cityAddToSuggestion(neighborhood.city);
                                this.saveCity.push(neighborhood.city.name.toLowerCase());
                            }
                        });
                    }
                } else {
                    element.neighborhoods.forEach((neighborhood: INeighborhood) => {
                        if (!this.neighborhoodInSuggestions(neighborhood)) {
                            this.neighborhoodAddToSuggestion(neighborhood);
                            this.saveNeighborhood.push(`${neighborhood.name.toLowerCase()} - ${neighborhood.city.name.toLowerCase()}`);
                        }
                        if (!this.cityInSuggestions(neighborhood.city)) {
                            this.cityAddToSuggestion(neighborhood.city);
                            this.saveCity.push(neighborhood.city.name.toLowerCase());
                        }
                    });
                }
            });
        });
        this.initiAutocompleteSearch();
    }

    private getFeatures() {
        this.propertyService.getPropertyBuildings().subscribe(buildings => {
            this.propertyService.propertyBuildings = buildings;

            buildings.forEach(building => {
                this.buildingsLabel.push(building);
            });
        });
    }

    private neighborhoodInSuggestions(neighborhood: INeighborhood): boolean {
        let neighborhoodExistsInSuggestions = false;
        const neighborhoodSuggestions: Array<ICityNeighborhoodSearch> = this.suggestions.filter(
            suggestion => suggestion.category === 'Bairro'
        );

        if (neighborhoodSuggestions.length > 0) {
            (this.saveNeighborhood.indexOf(neighborhood.name.toLowerCase()) !== -1)
                ? neighborhoodExistsInSuggestions = true
                : neighborhoodExistsInSuggestions = false;
        }
        return neighborhoodExistsInSuggestions;
    }

    private cityInSuggestions(city: ICity): boolean {
        let cityExistsInSuggestions = false;
        const citySuggestions: Array<ICityNeighborhoodSearch> = this.suggestions.filter(
            suggestion => suggestion.category === 'Cidade'
        );

        if (citySuggestions.length > 0) {
            (this.saveCity.indexOf(city.name.toLowerCase()) !== -1)
                ? cityExistsInSuggestions = true
                : cityExistsInSuggestions = false;
        }
        return cityExistsInSuggestions;
    }

    private cityAddToSuggestion(city: ICity) {
        this.suggestions.push({
            label: `${city.name} - ${city.administrative_area_level_1}`,
            city: city.name,
            state: city.administrative_area_level_1,
            category: 'Cidade'
        });
    }

    private neighborhoodAddToSuggestion(neighborhood: INeighborhood) {
        this.suggestions.push({
            label: `${neighborhood.name}, ${neighborhood.city.name} - ${neighborhood.city.administrative_area_level_1}`,
            neighborhood: neighborhood.name,
            city: neighborhood.city.name,
            state: neighborhood.city.administrative_area_level_1,
            category: 'Bairro'
        });
    }

    private initiAutocompleteSearch() {
        this.cityLabel = [];
        this.neighborhoodsLabel = [];
        this.suggestions.forEach(value => {
            switch (value.category) {
                case 'Cidade':
                    this.cityLabel.push(value.label);
                    break;
                case 'Bairro':
                    this.neighborhoodsLabel.push(value.label);
                    break;
            }
        });
        this.locationGroups = [
            {
                category: 'Cidade',
                label: this.cityLabel.sort((a, b) => a.localeCompare(b)),
            },
            {
                category: 'Bairro',
                label: this.neighborhoodsLabel.reduce((neighborhoodsLabel, neighborhood) => {
                    if (!neighborhoodsLabel.includes(neighborhood)) {
                        neighborhoodsLabel.push(neighborhood);
                    }
                    return neighborhoodsLabel;
                }, []).sort((a, b) => a.localeCompare(b)),
            },
            {
                category: 'Condomínio / Lançamento',
                label: this.buildingsLabel.sort((a, b) => a.localeCompare(b)),
            }
        ];
    }

    public searchAvailability(availability: string) {
        this.availabilitySelected = availability;
        this.setPropertyNeighborhoods();
    }

    public searchType(type: string) {
        this.typeSelected = type;
        this.setPropertyNeighborhoods();
    }

    public searchDates = (dates: Array<string>) => {
        this.startDateSelected = dates[0];
        this.endDateSelected = dates[1];
    }

    public searchAdults = (adults: number) => this.adultsSelected = adults;

    public searchChildren = (children: number) => this.childrenSelected = children;

    public searchMap(map: boolean) {
        this.router.navigate(['/buscar'], {
            queryParams: {
                availability: this.availabilitySelected,
                property_type: this.typeSelected,
                show_map: map,
                cursor: ''
            }
        });
    }

    public selectedLocation(select: ICityNeighborhoodSearch) {
        if (select) {
            switch (select.category) {
                case 'Cidade':
                    const city = select.label.split('-');
                    this.optionsSearch = 'city';
                    this.optionsSearchValue = city[0].trim();

                    if (this.availabilitySelected !== 'vacation_rental') {
                        this.router.navigate(['/buscar'], {
                            queryParams: {
                                availability: this.availabilitySelected,
                                property_type: this.typeSelected,
                                city: city[0].trim() || '',
                                cursor: ''
                            }
                        });
                    }
                    break;

                case 'Bairro':
                    const neighborhood = select.label.split(',');
                    this.optionsSearch = 'neighborhood';
                    this.optionsSearchValue = neighborhood[0].trim();

                    if (this.availabilitySelected !== 'vacation_rental') {
                        this.router.navigate(['/buscar'], {
                            queryParams: {
                                availability: this.availabilitySelected,
                                property_type: this.typeSelected,
                                neighborhood: neighborhood[0].trim() || '',
                                cursor: ''
                            }
                        });
                    }
                    break;

                case 'Condomínio / Lançamento':
                    const feature = select.label;
                    this.optionsSearch = 'building_name';
                    this.optionsSearchValue = feature;

                    if (this.availabilitySelected !== 'vacation_rental') {
                        this.router.navigate(['/buscar'], {
                            queryParams: {
                                availability: this.availabilitySelected,
                                property_type: this.typeSelected,
                                building_name: feature || '',
                                cursor: ''
                            }
                        });
                    }
                    break;
            }
        }
    }

    public submitForm(searchValue: string): void {
        localStorage.removeItem('search_params');
        (searchValue && isNumber(searchValue))
            ? this.propertyService.getProperty(`${searchValue}`).subscribe(
                propertyUrl => this.router.navigate([propertyUrl.site_url]),
                error => this.router.navigate(['/buscar'], { queryParams: { propertyNotFound: true } }),
            )
            : this.router.navigate(['/buscar'], {
                queryParams: {
                    availability: this.availabilitySelected,
                    property_type: this.typeSelected,
                    adults: this.adultsSelected,
                    children: this.childrenSelected,
                    dates: [(this.startDateSelected) ? this.startDateSelected : '', (this.endDateSelected) ? this.endDateSelected : ''],
                    city: (this.optionsSearch === 'city') ? this.optionsSearchValue : null,
                    neighborhood: (this.optionsSearch === 'neighborhood') ? this.optionsSearchValue : null,
                    building_name: (this.optionsSearch === 'building_name') ? this.optionsSearchValue : null,
                    cursor: ''
                }
            });
    }

    public imgErrorHandler(event) {
        event.target.onerror = null;
        event.target.src = `${this.appService.layoutBucketPath()}land.jpg`;
    }

    public checkImageUrl(url: string) {
        if (!url || url.length === 0) return `${this.appService.layoutBucketPath()}land.jpg`;
        return url;
    }

    public setSearchBarConfiguration() {
        this.landUrl = this.siteConfiguration.land;
        this.searchButtons = this.siteConfiguration.search_type;
        const videoExtensions = ['.mp4', '.avi', '.mov', '.wmv', '.flv', '.mkv'];
        if (videoExtensions.some(ext => this.landUrl.toLowerCase().endsWith(ext)) && this.searchButtons !== 'banner') {
            this.isVideo = true;
        }
    }
}
