<div class="container" style="display: flex; width: 100%;">

  <section id="search">
    @if (propertyTypesSearch) {
      <form class="form-search col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" [formGroup]="searchForm"
        (ngSubmit)="onSubmitForm()">
        @if (searchButtons !== 'banner') {
          <div class="row"
            [class.buttons-content]="searchButtons === 'ungrouped_buttons'"
            [class.buttons-content-grouped-buttons]="searchButtons === 'grouped_buttons'">
            <mat-button-toggle-group #availabilityGroup="matButtonToggleGroup" fxLayout="row" hideSingleSelectionIndicator
              [fxLayoutAlign]="searchButtons === 'grouped_buttons' ? 'center center' : 'space-evenly center'"
              (change)="onSearchAvailability(availabilityGroup.value)">
              @for (finality of propertyTypesSearch | objectgroups; track finality) {
                <mat-button-toggle class="btn-search col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"
                  [class.background-trans]="searchButtons === 'ungrouped_buttons'"
                  [class.background-light]="searchButtons === 'grouped_buttons'"
                  [checked]="finality === 'buy'"
                  [value]="finality">
                  <span>{{ translateAvailability(finality) }}</span>
                </mat-button-toggle>
              }
              @if (parameters?.site_home_button.active && parameters?.site_home_button.button_type === 'property_filter') {
                <button
                  class="btn-search col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3" type="button"
                  [class.background-trans]="searchButtons === 'ungrouped_buttons'"
                  [class.background-light]="searchButtons === 'grouped_buttons'">
                  <imobzi-custom-button [buttonHome]="parameters?.site_home_button">
                  </imobzi-custom-button>
                </button>
              }
            </mat-button-toggle-group>
          </div>
        }
        <div class="row group-search">
          <div class="container-search col-md-12 col-lg-12 col-xl-12"
            [class.container-search-grouped-buttons]="searchButtons === 'grouped_buttons'">
            @if (searchButtons === 'banner') {
              <mat-accordion class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                <mat-expansion-panel #availabilityPanel="matExpansionPanel"
                  (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="h3">
                      {{ translateAvailability(availabilitySelected) || "Disponibilidade" }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul>
                    @for (finality of propertyTypesSearch | objectgroups; track finality) {
                      @if (availabilitySelected !== finality) {
                        <li class="h3"
                          (click)="onSearchAvailability(finality)">
                          <a>{{ translateAvailability(finality) }}</a>
                        </li>
                      }
                    }
                  </ul>
                </mat-expansion-panel>
              </mat-accordion>
              <span class="divisor"></span>
            }
            @if (availabilitySelected !== 'vacation_rental') {
              <mat-accordion class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                <mat-expansion-panel #typePanel="matExpansionPanel" (opened)="panelOpenState2 = true"
                  (closed)="panelOpenState2 = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="h3">
                      {{ typeSelected || 'Tipo' }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul>
                    @for (finalitys of propertyTypesSearch[availabilitySelected]; track finalitys) {
                      <li class="h3 property-finality bold bold">
                        <a>{{ translateFinality(finalitys.property_finality) }}</a>
                      </li>
                      @for (type of finalitys.types; track type) {
                        @if (typeSelected !== type.property_type) {
                          <li
                            (click)="onSearchType(type.property_type)" class="h3 property-type">
                            {{ type.property_type }}
                          </li>
                        }
                      }
                    }
                  </ul>
                </mat-expansion-panel>
              </mat-accordion>
              <span class="divisor"></span>
            }
            <div
              [class]="(availabilitySelected !== 'vacation_rental') ? 'group-input_autocomplete col-md-7 col-lg-7 col-xl-7' : 'group-input_autocomplete padding-left-22 padding-right-22 col-md-4 col-lg-4 col-xl-4'">
              <input #searchInput class="h3 input-search col-md-12 col-lg-12 col-xl-12"
                [placeholder]="(availabilitySelected !== 'vacation_rental') ? 'Procure por uma cidade, bairro, condomínio ou código' : 'Para onde você vai?'"
                formControlName="locationGroup" autocomplete="off" (blur)="hideDropdownList()"
                (focus)="showDropdownList()" (input)="reloadListInDelay($event)"
                (keydown)="inputElKeyHandler($event)" type="text">
              @if (dropdownVisible) {
                <div [class.baloon-options]="availabilitySelected === 'vacation_rental'"
                  class="option-group col-md-12 col-lg-12 col-xl-12">
                  <ul>
                    <div id="overflow" class="overflow-scroll">
                      @for (suggestion of locationGroupOptions | async; track suggestion; let index = $index) {
                        @if (suggestion.label.length > 0) {
                          <li class="option-category bold h3 bold">
                            {{suggestion.category}}
                          </li>
                        }
                        @for (label of suggestion.label; track i; let i = $index) {
                          <li
                            class="option-item h3"
                            [ngClass]="{selected: i === itemIndex && index === groupIndex}"
                            (mousedown)="selectOne(suggestion.category, label)">
                            {{ label }}
                          </li>
                        }
                      }
                    </div>
                  </ul>
                </div>
              }
            </div>
            @if (availabilitySelected === 'vacation_rental') {
              <span class="divisor"></span>
              <div class="vacation-rental-option-search col-md-2 col-lg-2 col-xl-2">
                <div (click)="showCalendar('start')" class="h3 clickable-option">
                  <div>
                    Chegada<br>
                    @if (startDateSelected) {
                      <span>{{ convertDateStringToFullDate(startDateSelected,
                      'abbreviatedDate') }}</span>
                    }
                  </div>
                  @if (!startCalendarVisible) {
                    <mat-icon>expand_more</mat-icon>
                  }
                  @if (startCalendarVisible) {
                    <mat-icon>close</mat-icon>
                  }
                </div>
              </div>
              <span class="divisor"></span>
              <div class="vacation-rental-option-search col-md-2 col-lg-2 col-xl-2">
                <div (click)="showCalendar('end')" class="h3 clickable-option">
                  <div>
                    Saída<br>
                    @if (endDateSelected) {
                      <span>{{ convertDateStringToFullDate(endDateSelected,
                      'abbreviatedDate') }}</span>
                    }
                  </div>
                  @if (!endCalendarVisible) {
                    <mat-icon>expand_more</mat-icon>
                  }
                  @if (endCalendarVisible) {
                    <mat-icon>close</mat-icon>
                  }
                </div>
              </div>
              @if (startCalendarVisible || endCalendarVisible) {
                <imobzi-calendar-availability
                  [startDateSelected]="startDateSelected" [endDateSelected]="endDateSelected"
                  [calendarAvailability]="calendarAvailability" [singleCalendar]="singleCalendar"
                  (searchDates)="onsearchDates($event)">
                </imobzi-calendar-availability>
              }
              <span class="divisor"></span>
              <div class="vacation-rental-option-search col-md-2 col-lg-2 col-xl-2">
                <div (click)="showGuestsCounter()" class="h3 clickable-option">
                  <div>
                    Hóspedes<br>
                    @if (adultsSelected + childrenSelected > 0) {
                      <span>{{ adultsSelected +
                        childrenSelected }} {{(adultsSelected + childrenSelected > 1) ? 'hóspedes':
                      'hóspede'}}</span>
                    }
                  </div>
                  @if (!guestCounterVisible) {
                    <mat-icon>expand_more</mat-icon>
                  }
                  @if (guestCounterVisible) {
                    <mat-icon>close</mat-icon>
                  }
                </div>
              </div>
              @if (guestCounterVisible) {
                <imobzi-guests-counter [adultsSelected]="adultsSelected" [childrenSelected]="childrenSelected"
                  (searchAdults)="onSearchAdults($event)" (searchChildren)="onSearchChildren($event)"
                  >
                </imobzi-guests-counter>
              }
            }
            <button type="submit"
              class="btn-search-form background-trans border-trans-1 col-md-1 col-lg-1 col-xl-1">
              <mat-icon>search</mat-icon>
            </button>
          </div>
        </div>
      </form>
    }
  </section>
  @if (searchButtons === 'banner') {
    <section class="map-button">
      <button (click)="onSearchMap(true)" class="btn-icon btn-disabled-2 btn-md-48 map" matTooltip="Exibir Mapa">
        <img [src]="layoutBucketPath + 'icon-google-maps.svg'" alt="Mapa">
      </button>
    </section>
  }
</div>
@if (panelOpenState1 || panelOpenState2 || guestCounterVisible) {
  <div class="accordion-dismiss" (click)="closePanel()">
  </div>
}
