<section id="breadcrumbs" fxLayout="row" fxLayoutAlign="start center">
  <a routerLink="/">
    <h4>Home</h4>
  </a>
  @if (category) {
    <mat-icon aria-label="Next">chevron_right</mat-icon>
    <a [href]="categoryUrl"><h4 class="bold">{{ category }} </h4></a>
  }
  @if (pageTitle) {
    <mat-icon aria-label="Next">chevron_right</mat-icon>
    <h4 class="bold">{{ pageTitle }}</h4>
  }
  @if (property && property.building_name) {
    <mat-icon aria-label="Next">chevron_right</mat-icon>
    <a (click)="onOpenBuildings()">
      <h4 class="bold">{{ property.building_name }}</h4>
    </a>
  }
  @if (property) {
    <mat-icon aria-label="Next">chevron_right</mat-icon>
    <a 
      [routerLink]="[
      '/' + propertyAvailability, 
      normalizeSendParams(property.city), 
      normalizeSendParams(property.neighborhood), 
      normalizeSendParams(property.property_type)]"
    >
      <h4 class="bold">{{ title }}</h4>
    </a>
  }
</section>
