import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { SiteContact } from 'src/app/interface/contact';
import { Phone } from 'src/app/interface/parameters';
import { FormService } from 'src/app/service/form.service';
import { PATTERN } from 'src/app/utils/constants';
import { formatWhatsAppPhone } from 'src/app/utils/phone.utils';

@Component({
    selector: 'imobzi-whatsapp-dialog',
    templateUrl: './whatsapp-dialog.component.html',
    styleUrls: ['./whatsapp-dialog.component.scss']
})
export class WhatsappDialogComponent implements OnInit {

    constructor(
        private titlePage: Title,
        private formService: FormService,
        public dialogRef: MatDialogRef<WhatsappDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data?: any
    ) { }

    title = `Fale pelo Whatsapp`;

    formContent: any;
    formData: SiteContact;
    STEP_ITEMS = [];
    DATA_STEP_1: {};
    buttonTitle = 'Ir para o Whatsapp';
    messageRequired = `É necessário preencher o campo!`;
    message: string;
    formMessage: string;
    phone: Phone;
    propertyCode: string;
    buttonClass = 'btn-whatsapp-1';

    loading = false;

    ngOnInit() {
        (this.data.message)
            ? this.message = this.data.message
            : this.message = 'Olá, vim através do seu site e gostaria de mais informações';

        (this.data.formMessage)
            ? this.formMessage = this.data.formMessage
            : this.formMessage = 'Contato via WhatsApp';

        if (this.data.code) {
            this.propertyCode = this.data.code;
        }

        this.phone = this.data.phone;

        this.titlePage.setTitle(this.titlePage.getTitle());
        this.formContent = this.stepItens();
    }

    private stepItens() {
        return this.STEP_ITEMS = [
            { label: '1', data: this.dataStep1() }
        ];
    }

    private dataStep1() {
        this.DATA_STEP_1 = {
            name: {
                type: 'text',
                placeholder: 'Nome',
                validations: {
                    required: true,
                    minLength: 2
                },
                class: 'contact-form gd-xs-1-12 gd-sm-1-12 gd-md-1-12 gd-lg-1-12 gd-xl-1-12',
                errors: {
                    required: this.messageRequired,
                    minlength: 'Digite no minimo de 2 caracteres'
                }
            },
            phone: {
                type: 'phone',
                placeholder: 'Telefone',
                validations: {
                    required: true,
                    pattern: PATTERN.phone
                },
                class: 'contact-form gd-xs-1-12 gd-sm-1-12 gd-md-1-12 gd-lg-1-12 gd-xl-1-12',
                errors: {
                    required: this.messageRequired,
                    pattern: 'Por favor adicione um Telefone valido!'
                }
            },
        };

        if (this.data.showEmail) {
            this.DATA_STEP_1['email'] = {
                type: 'email',
                placeholder: 'E-mail',
                validations: {
                    required: true,
                    email: true
                },
                class: 'contact-form gd-xs-1-12 gd-sm-1-12 gd-md-1-12 gd-lg-1-12 gd-xl-1-12',
                errors: {
                    required: this.messageRequired,
                    email: 'Email inválido'
                }
            };
        }

        return this.DATA_STEP_1
    }

    closeDialog(value: boolean): void {
        this.dialogRef.close();
    }

    public onFormSubmit(formData: SiteContact) {
        this.formData = formData;

        this.loading = true;

        this.formData['message'] = this.formMessage;
        this.formData['property_code'] = this.propertyCode;
        window.open(`https://wa.me/${formatWhatsAppPhone(this.phone.number)}?text=${encodeURIComponent(this.message)}`);
        if (this.formData) {
            this.formService.contact(this.formData).subscribe(
                () => {
                    this.loading = false;
                    this.closeDialog(true);
                },
                error => { this.loading = false; }
            );
        }
    }

}
