<imobzi-section-title
    [title]="title"
    [subtitle]="subtitle">
</imobzi-section-title>

<div class="property-container">
    <img 
        class="img-rounded" 
        [src]="property.cover_photo.url" 
        (error)="imgErrorHandler($event)"
        alt="Ver mais detalhes de {{property.property_type}} em {{property.neighborhood}} - {{property.city}}/{{property.state}}"
    >

    <div class="property-content">
        <p class="property-city">{{property.city}}</p>
        <h2 class="property-neighborhood">{{property.neighborhood}}</h2>
        @if (title === 'Agendar uma Visita') {
            <h4 class="property-description">
                {{property.property_type }}
                {{(property.tags.indexOf('sale') !== -1)? 'à Venda': ''}}{{(property.tags.length === 2)? ' ou ': ''}}
                {{(property.tags.indexOf('rent') !== -1)? 'para Locação': ''}}
                {{(property.tags.indexOf('vacation_rental') !== -1)? 'para Temporada': ''}}
                @if (property.useful_area > 0) {
                    , {{ property.useful_area }} {{property.measure_type | lowercase}}
                } @else if (property.area > 0) {
                    , {{ property.area }} {{ property.measure_type | lowercase }}
                } @else if (property.lot_area > 0) {
                    , {{ property.lot_area }} {{ property.measure_type | lowercase }}
                }
            </h4>
            <h4 class="highlight-value">
                @if (property.site_publish_price) {
                    @if (property.sale_value > 0) {
                        {{ property.sale_value | currency:'BRL' : 'symbol' : '1.0-0' }}
                    } @else if (property.rental_value > 0) {
                        {{ property.rental_value | currency:'BRL' : 'symbol' : '1.0-0' }}
                    }
                } @else { <b>Consulte!</b> }
            </h4>
        } @else {
            <h4 class="property-description">
                {{property.property_type}}
                {{(property.vacation_rental_guests >= 1)? 'para ' + property.vacation_rental_guests : ''}}
                @if (property.vacation_rental_guests >= 1) {
                    {{ (property.vacation_rental_guests >= 2)? 'hóspedes': 'hóspede'}}
                }
            </h4>
            <h4 class="highlight-value">
                @if (property.site_publish_price) {
                    {{ property.rental_value | currency:'BRL' : 'symbol' : '1.0-0' }}
                    @if (intervalDays >= 1) {
                        x {{(intervalDays >= 2) ? intervalDays + ' noites' : intervalDays + ' noite'}}
                        = {{ intervalDays * property.rental_value | currency:'BRL' : 'symbol' : '1.0-0' }} / total
                    }
                } @else { <b>Consulte!</b> }
            </h4>
        }
    </div>
</div>

@if (!displaySuccess && !msgContactSuccess) {
    <button
        class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8"
        [class.btn]="!stepVisit"
        [class.btn-secondary-1]="!stepVisit"
        [class.btn-md]="!stepVisit"
        [class.btn-icon]="stepVisit"
        [class.btn-light-1]="stepVisit"
        [class.btn-lg-64]="stepVisit"
        (click)="btnChanged(stepVisit = !stepVisit)"
    >
        @if (!stepVisit) {
            <span>
                {{ (formType === 'reserve' || (property.vacation_rental && formType !== 'schedule_visit')) ? 'Reservar' : 'Agendar uma Visita' | uppercase}}
            </span>
        }
        @if (stepVisit) {
            <mat-icon
                class="link-light material-icons-round"
                aria-label="Previous">
                chevron_left
            </mat-icon>
        }
    </button>
}

@if (msgContactSuccess) { <h2 class="h2 color-light">{{msgContactSuccess}}</h2> }
