@switch (buttonHome.button_type) {
  @case ('external_link') {
    <a 
      class="bold" 
      [href]="buttonHome.url" 
      target="_blank" 
      [attr.aria-label]="buttonHome.label"
      rel="noopener"
    >
      {{ buttonHome.label | uppercase }}
    </a>
  }
  @case ('linked_content') {
    <a 
      class="bold" 
      [routerLink]="buttonHome.url"
      [attr.aria-label]="buttonHome.label"
      rel="noopener"
    >
      {{ buttonHome.label | uppercase }}
    </a>
  }
  @case ('property_filter') {
    <a 
      class="bold" 
      [routerLink]="buttonHome.url" 
      [queryParams]="buttonHome.query_params" 
      [attr.aria-label]="buttonHome.label" 
      rel="noopener"
    >
      {{ buttonHome.label }}
    </a>
  }
}