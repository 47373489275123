@if (parameters) {
<ul class="social-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start">
    @for (socialNetwork of parameters.social_networks; track socialNetwork) {
    <li class="social-content">
        <a class="social-link" [href]="getSocialNetworkUrl(socialNetwork)" target="_blank" rel="noopener"
            title="{{ socialNetwork.name }}" aria-label="Rede Sociais">
            <ng-container [ngTemplateOutlet]="icon"
                [ngTemplateOutletContext]="{ socialNetwork: socialNetwork }"></ng-container>
        </a>
    </li>
    }
</ul>
}

<ng-template #icon let-socialNetwork="socialNetwork">
    @if (socialNetwork.name | lowercase) {
    <fa-icon
        [icon]="['fab', socialNetwork.name === 'X' ? 'x-twitter' : socialNetwork.name.replace('Facebook', 'Facebook-f') | lowercase]"></fa-icon>
    }
</ng-template>
