import { Menu, GroupedMenus } from 'src/app/interface/menu';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ContactRealEstateDialogComponent } from '../../modal/contact-real-estate/contact-real-estate-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FinancingSimulatorDialogComponent } from '../../modal/financing-simulator/financing-simulator-dialog.component';
import { Parameters, SiteConfiguration } from 'src/app/interface/parameters';

@Component({
    selector: 'imobzi-header-menu',
    templateUrl: './header-menu.component.html',
    styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit {

    @Input() mainMenu: Array<GroupedMenus>;
    @Input() calledFromHome: boolean;
    @Input() parameters: Parameters;
    @Input() siteConfiguration: SiteConfiguration;
    @Input() fontColor: string;

    public backgroundType: string;
    public searchButtons: string;
    public expansionMenus: Array<{ [key: string]: boolean }> = [];

    constructor(
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.initSiteConfiguration();
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: Event) {
        Object.keys(this.expansionMenus).forEach(key => this.expansionMenus[key] = true);
    }

    changeExpensionPanelState(menu: string, state: boolean) {
        this.expansionMenus[menu] = state;
    }

    public onMenuItemClicked(menuItem: Menu) {
        if (menuItem.name.toLowerCase() === 'simule um financiamento') {
            this.openSimulatorDialog();
        } else {
            window.open(menuItem.url);
        }
    }

    private openSimulatorDialog(): void {
        this.dialog.open(FinancingSimulatorDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: { parameters: this.parameters }
        });
    }

    openDialog(): void {
        this.dialog.open(ContactRealEstateDialogComponent, {
            panelClass: 'custom-dialog-container'
        });
    }

    initSiteConfiguration() {
        this.backgroundType = this.siteConfiguration.theme.header_color;
        this.searchButtons = this.siteConfiguration.search_type;
    }
}
