import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnChanges, OnInit, PLATFORM_ID, Renderer2, SimpleChanges } from '@angular/core';
import { Parameters, SiteConfiguration } from 'src/app/interface/parameters';
import { AppService } from 'src/app/service/app.service';
import { isColorDark } from 'src/app/utils/utils';

@Component({
    selector: 'imobzi-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnChanges {

    @Input() parameters: Parameters;
    @Input() siteConfiguration: SiteConfiguration;
    public layoutBucketPath: string = this.appService.layoutBucketPath();
    public hostname: string;
    public googleCertificate: string;
    public sslCertificate: string;

    constructor(
        public appService: AppService,
        private renderer: Renderer2,
        private el: ElementRef,
        @Inject(PLATFORM_ID) private platformId: Object
    ) { }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.hostname = document.location.hostname.replace('www.', '');
        }
        this.googleCertificate = `https://transparencyreport.google.com/safe-browsing/search?url=${this.hostname}&hl=pt_BR`;
        this.sslCertificate = `https://www.sslshopper.com/ssl-checker.html#hostname=${this.hostname}`;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.siteConfiguration && this.siteConfiguration) {
            this.setFooterColor();
        }
    }

    setFooterColor() {
        const color = isColorDark(this.siteConfiguration.theme.footer_color) ? '#ffffff' : '#424242'
        if (isPlatformBrowser(this.platformId)) {
            document.documentElement.style.setProperty('--footer-color', color);
        } else {
            this.renderer.setStyle(this.el.nativeElement, '--footer-color', color);
        }
    }

}
