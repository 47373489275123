import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from './components/popup/popup.component';
import { Component, ElementRef, Inject, OnInit, Optional, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Meta, Title } from '@angular/platform-browser';
import { ActivationEnd, NavigationStart, Router } from '@angular/router';
import { IGoogleAnalytics, IIntegration } from './interface/integration';
import { Parameters, SiteConfiguration } from './interface/parameters';
import { HomeService } from 'src/app/service/home.service';
import { AppService } from './service/app.service';
import { IntegrationsService } from './service/integrations.service';
import { ParametersService } from './service/parameters.service';
import { ChatboxPosition, Crisp } from 'crisp-sdk-web';
import { SearchParams } from './interface/search-params';
import { ContentService } from 'src/app/service/content.service';
import { Content } from 'src/app/interface/content';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'imobzi-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    defaultTitle: string;
    defaultDescription: string;
    parameters: Parameters;
    googleMaps: IIntegration = {} as IIntegration;
    crisp: IIntegration = {} as IIntegration;
    googleAnalytics: IGoogleAnalytics = {} as IGoogleAnalytics;
    calledFromHome: boolean;
    calledFromDetail: boolean;
    calledFromContent: boolean;
    content: Content;
    landingPage: boolean;
    urlContent: string;
    originalUrl: string;
    saveSearchParams: SearchParams = {} as SearchParams;
    siteConfiguration: SiteConfiguration;
    key: string;

    @ViewChild('sidenav') sidenav: MatSidenav;

    constructor(
        private el: ElementRef,
        private appService: AppService,
        private dialog: MatDialog,
        private integrationService: IntegrationsService,
        private parametersService: ParametersService,
        private title: Title,
        private meta: Meta,
        private router: Router,
        private contentService: ContentService,
        private homeService: HomeService,
        private renderer: Renderer2,
        @Inject(PLATFORM_ID) private platformId: Object,
        @Optional() @Inject('ORIGINAL_URL') originalUrl: string
    ) {
        this.originalUrl = originalUrl;
    }
    ngOnInit(): void {
        this.getSiteConfigurations();
        this.getParameters();
        this.getCrispChat();
        this.getGoogleMaps();
        this.getGoogleAnalytics();
        this.router.events.subscribe((evt) => {
            this.isCalledFromHome(evt);
            if (evt instanceof NavigationStart && this.parameters) {
                this.setMetaTags();
            }
        });
    }

    private getSiteConfigurations() {
        if (isPlatformBrowser(this.platformId)) {
            this.key = location.pathname.split('site-home-preview/')[1];
        }
        if (this.key) {
            this.homeService.getSitePreviewData(this.key).subscribe(
                response => {
                    this.siteConfiguration = response;
                    this.setTheme();
                    this.setFavicon();
                },
                () => this.router.navigate(['pagina-nao-encontrada'])
            );
        } else {
            this.homeService.getSiteConfiguration().subscribe(response => {
                this.siteConfiguration = response;
                this.setTheme();
                this.setFavicon();
            });
        }
    }

    private setTheme() {
        const theme = this.siteConfiguration.theme;
        if (isPlatformBrowser(this.platformId)) {
            document.documentElement.style.setProperty('--primary-background', theme.primary_color);
            document.documentElement.style.setProperty('--secondary-background', theme.secondary_color);
            document.documentElement.style.setProperty('--buttom-background', theme.button_color);
            document.documentElement.style.setProperty('--dialog-color', theme.dialog_color);
            document.documentElement.style.setProperty('--footer-background', theme.footer_color);
            document.documentElement.style.setProperty('--second_footer', theme.second_footer_color);
            document.documentElement.style.setProperty('--background-page', theme.background_color);
            document.documentElement.style.setProperty('--title', theme.title_color);
            document.documentElement.style.setProperty('--subtitle', theme.subtitle_color);
            document.documentElement.style.setProperty('--card', theme.card_color);
            document.documentElement.style.setProperty('--inactive', theme.inactive_color);
            document.documentElement.style.setProperty('--filter-background', theme.filter_background_color);
            document.documentElement.style.setProperty('--header-background', theme.header_color);
        } else {
            this.renderer.setStyle(this.el.nativeElement, '--primary-background', theme.primary_color);
            this.renderer.setStyle(this.el.nativeElement, '--secondary-background', theme.secondary_color);
            this.renderer.setStyle(this.el.nativeElement, '--buttom-background', theme.button_color);
            this.renderer.setStyle(this.el.nativeElement, '--dialog-color', theme.dialog_color);
            this.renderer.setStyle(this.el.nativeElement, '--footer-background', theme.footer_color);
            this.renderer.setStyle(this.el.nativeElement, '--second_footer', theme.second_footer_color);
            this.renderer.setStyle(this.el.nativeElement, '--background-page', theme.background_color);
            this.renderer.setStyle(this.el.nativeElement, '--title', theme.title_color);
            this.renderer.setStyle(this.el.nativeElement, '--subtitle', theme.subtitle_color);
            this.renderer.setStyle(this.el.nativeElement, '--card', theme.card_color);
            this.renderer.setStyle(this.el.nativeElement, '--inactive', theme.inactive_color);
            this.renderer.setStyle(this.el.nativeElement, '--filter-background', theme.filter_background_color);
            this.renderer.setStyle(this.el.nativeElement, '--header-background', theme.header_color);
        }
    }


    private isCalledFromContent() {
        const locationUrl = isPlatformBrowser(this.platformId) ? location.pathname : this.originalUrl;
        (locationUrl.slice(1) === this.urlContent)
            ? this.calledFromContent = true
            : this.calledFromContent = false;
    }

    private getContentConfigurations() {
        if (this.contentService.contentConfiguration) {
            this.content = this.contentService.contentConfiguration;
            this.landingPage = this.content.landing_page;
            this.urlContent = this.content.url;
            this.isCalledFromContent();
        } else {
            this.contentService.contentConfiguration$.subscribe(content => {
                this.content = content;
                this.landingPage = this.content.landing_page;
                this.urlContent = this.content.url;
                this.isCalledFromContent();
            });
        }
    }

    private isCalledFromHome(evt) {
        if (evt instanceof ActivationEnd) {
            (evt.snapshot.routeConfig.path === '' || evt.snapshot.routeConfig.path === 'site-home-preview/:id' || evt.snapshot.routeConfig.path === 'a/:database')
                ? this.calledFromHome = true
                : this.calledFromHome = false;
        }

        if (evt instanceof ActivationEnd) {
            (evt.snapshot.routeConfig.path === 'imovel/:urlPath' || evt.snapshot.routeConfig.path === 'c/:code')
                ? this.calledFromDetail = false
                : this.calledFromDetail = true;
        }

        if (evt instanceof ActivationEnd) {
            this.getContentConfigurations();
        }
    }

    openPopup() {
        if (this.parameters.site_popup) {
            const endDate = new Date(this.parameters.site_popup.end_date);
            const startDate = new Date(this.parameters.site_popup.start_date);
            const currentDate = new Date();

            if (currentDate >= startDate && currentDate <= endDate) {
                this.dialog.open(PopupComponent, {
                    panelClass: ['custom-dialog-container', 'popup-dialog'],
                    data: { site_popup: this.parameters.site_popup }
                });
            }
        }
    }

    private getParameters() {
        this.parametersService.getParameters().subscribe(parameters => {
            this.parameters = parameters;
            this.addCustomCode();
            this.openPopup();
            this.setMetaTags();
        },
            () => {
                this.title.setTitle('Imobiliária');
            });
    }

    private getCrispChat() {
        this.integrationService.getIntegrationByName('crisp').subscribe((integration: IIntegration) => {
            if (integration && integration.crisp && integration.crisp.website_id && integration.crisp.show_chat) {
                this.crisp = integration;
                if (isPlatformBrowser(this.platformId)) {
                    this.setCrispChat();
                }
            }
        });
    }

    private setCrispChat() {
        Crisp.configure(this.crisp.crisp.website_id);
        Crisp.setPosition(ChatboxPosition.Left);
        Crisp.setZIndex(2);
        Crisp.load();
    }

    private getGoogleMaps() {
        const url = 'https://maps.googleapis.com/maps/api/js';
        this.integrationService.getIntegrationByName('google_maps').subscribe((googleMaps: IIntegration) => {
            this.googleMaps = googleMaps || {} as IIntegration;
            if (this.googleMaps.api_key) {
                this.setScriptUrl(`${url}?key=${this.googleMaps.api_key}`, 'googleMapsScript');
            }
        });
    }

    private getGoogleAnalytics() {
        this.integrationService.getIntegrationByName('google_analytics').subscribe((integration: IIntegration) => {
            if (integration && integration.google_analytics && integration.google_analytics.tracking_id) {
                this.googleAnalytics = integration.google_analytics;
                this.setGoogleAnalytics();
            }
        });
    }

    private setScriptUrl(scriptUrl: string, scriptId: string) {
        if (isPlatformBrowser(this.platformId)) {
            const scriptNode = document.createElement('script');
            scriptNode.async = true;
            scriptNode.src = `${scriptUrl}`;
            scriptNode.id = scriptId;
            document.body.appendChild(scriptNode);
        }
    }

    private setGoogleAnalytics() {
        if (isPlatformBrowser(this.platformId)) {
            this.setScriptUrl(`https://www.googletagmanager.com/gtag/js?id=${this.googleAnalytics.tracking_id}`, 'googleAnalyticsSxript');
            const head = document.getElementsByTagName('head')[0];
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.innerHTML = `
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                    dataLayer.push(arguments);
                }
                gtag('js', new Date());
                gtag('config', '${this.googleAnalytics.tracking_id}');
            `;
            head.appendChild(script);
        }
    }

    private addCustomCode() {
        if (this.parameters.site_script && isPlatformBrowser(this.platformId)) {
            document.head.appendChild(document.createRange().createContextualFragment(this.parameters.site_script.script_head));
            document.body.appendChild(document.createRange().createContextualFragment(this.parameters.site_script.script_body));
        }
    }

    private setMetaTags() {
        this.setMetaTitle();
        if (this.parameters.site_meta_description) {
            this.meta.updateTag({ name: 'description', content: this.parameters.site_meta_description });
            this.meta.updateTag({ property: 'og:description', content: this.parameters.site_meta_description });
        } else {
            this.defaultDescription = `${this.parameters.company_name} - Imobiliária em ${this.parameters.city}`;
            this.defaultDescription += ' com imóveis para venda, locação e lançamentos';
            this.meta.updateTag({ name: 'description', content: this.defaultDescription });
            this.meta.updateTag({ property: 'og:description', content: this.defaultDescription });
            this.meta.updateTag({ property: 'og:url', content: 'https://' + this.parameters.site });
            this.meta.updateTag({ property: 'og:type', content: 'website' });
            this.meta.updateTag({ property: 'fb:app_id', content: '559276504090135' });
        }
        this.meta.updateTag({ property: 'og:image', content: this.appService.logoUrl });
    }

    private setMetaTitle() {
        if (this.parameters.site_title) {
            this.title.setTitle(this.parameters.site_title);
            this.meta.updateTag({ property: 'og:title', content: this.parameters.site_title });
        } else {
            (this.parameters.company_type === 'legal_entity')
                ? this.defaultTitle = 'Imobiliária ' + this.parameters.company_name
                : this.defaultTitle = this.parameters.company_name + ' corretor de imóveis';

            if (this.parameters.city) {
                this.defaultTitle += ` em ${this.parameters.city}`;
            }
            this.title.setTitle(this.defaultTitle);
            this.meta.updateTag({ property: 'og:title', content: this.defaultTitle });
        }
    }

    private setFavicon() {
        const favicon = this.renderer.createElement('link');
        this.renderer.setAttribute(favicon, 'rel', 'icon');
        this.renderer.setAttribute(favicon, 'type', 'image/x-icon');
        this.renderer.setAttribute(favicon, 'href', (this.siteConfiguration && this.siteConfiguration.favicon) ? this.siteConfiguration.favicon : '');
        const head = this.renderer.selectRootElement('head', true);
        this.renderer.appendChild(head, favicon);
    }

}
