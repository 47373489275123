<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav class="sidenav" fixedInViewport [attr.role]="'dialog'" [mode]="'over'" [opened]="false" position="end">
        <button class="btn-icon color-light btn-light-1 sidenav-close" type="button" aria-label="Toggle sidenav" (click)="sidenav.toggle()">
            <mat-icon aria-label="Close menu">close</mat-icon>
        </button>
        <imobzi-menu fxLayout="column" fxLayoutAlign="space-between flex-start" [parameters]="parameters"
            [sidenav]="sidenav"/>
    </mat-sidenav>
    <mat-sidenav-content>
        @if (((calledFromDetail && !calledFromContent) || (calledFromContent && !landingPage))) {
            <imobzi-header [parameters]="parameters" [sidenav]="sidenav" [calledFromHome]="calledFromHome" [siteConfiguration]="siteConfiguration"/>
        }
        <router-outlet/>
        @if (((calledFromDetail && !calledFromContent) || (calledFromContent && !landingPage))) {
            <imobzi-footer [parameters]="parameters" [siteConfiguration]="siteConfiguration"/>
        }
    </mat-sidenav-content>
</mat-sidenav-container>
