<div class="dates-container">
  <h3 class="h3 color-light">Qual o melhor dia?</h3>
  <mat-button-toggle-group hideSingleSelectionIndicator class="days" [value]="nextDay" #groupDays="matButtonToggleGroup" (change)="onDayChange(groupDays.value)">
    @for (day of days; track day) {
      <mat-button-toggle
        class="btn-icon btn-light-1"
        [value]="day.name"
        aria-label="Dia">
        {{day.abbr}}
      </mat-button-toggle>
    }
  </mat-button-toggle-group>

  <h3 class="h3 color-light">Melhor horário?</h3>
  <mat-button-toggle-group hideSingleSelectionIndicator class="hours" #groupSchedule="matButtonToggleGroup" value="Manhã" (change)="onScheduleChange(groupSchedule.value)">
    @for (schedule of schedules; track schedule) {
      <mat-button-toggle
        class="btn_hour btn btn-light-1 btn-md"
        [value]="schedule"
        aria-label="Horario"
        >
        {{schedule}}
      </mat-button-toggle>
    }
  </mat-button-toggle-group>

  <button class="next col-md-10 btn btn-primary-1 btn-md"
    (click)="onNextChange()">
    Continuar
  </button>
</div>
