import { DialogWhatsapp, Parameters, Phone } from '../interface/parameters';
import { MatDialog } from '@angular/material/dialog';
import { WhatsappDialogComponent } from 'src/app/components/modal/whatsapp-dialog/whatsapp-dialog.component';

export function setPhotoUrlResize(photoUrl: string, resize: number = 295): string {
    if (photoUrl.slice(photoUrl.length - 3) === '=s0') {
        return photoUrl.slice(0, photoUrl.length - 3) + '=s' + resize;
    } else {
        return photoUrl + '=s' + + resize;
    }
}

export function removeHtmlTags(text: string) {
    return text.replace(/<[^>]+>|&nbsp;/ig, ' ');
}

export function onPhoneClicked(phone: Phone, dialog: MatDialog, params: Parameters, dialogData?: DialogWhatsapp) {
    if (phone.type && phone.type.toLowerCase() === 'whatsapp') {
        const showEmail = params.realtor.site_show_email
        if (params.realtor.show_whatsapp_modal) {
            dialog.open(WhatsappDialogComponent, {
                panelClass: ['custom-dialog-container', 'whatsapp-dialog'],
                data: { ...dialogData, phone, showEmail }
            });
        } else {
            (dialogData && dialogData.message)
            ? window.open(`https://wa.me/${formatWhatsAppPhone(phone.number)}?text=${encodeURIComponent(dialogData.message)}`)
            : window.open(`https://wa.me/${formatWhatsAppPhone(phone.number)}`);
        }
    } else {
        window.open(`tel:${phone.number}`);
    }
}

export function formatWhatsAppPhone(phoneNumber: string) {
    if (phoneNumber.indexOf('+55') === -1) {
        phoneNumber = `+55${phoneNumber}`;
    }
    return phoneNumber.replace(/[^\d]/g, '');
}

export function translateFinality(finality: string) {
    switch (finality) {
        case 'residential':
            return 'Residencial';
        case 'commercial':
            return 'Comercial';
        case 'rural':
            return 'Rural';
        default:
            return finality;
    }
}

export function translateAvailability(availability: string) {
    switch (availability) {
        case 'buy':
            return 'Comprar';
        case 'rent':
            return 'Alugar';
        case 'vacation_rental':
            return 'Temporada';
        default:
            return availability;
    }
}

export function translateAvailabilityParams(availability: string) {
    switch (availability) {
        case 'comprar':
            return 'buy';
        case 'alugar':
            return 'rent';
        case 'temporada':
            return 'vacation_rental';
        default:
            return availability;
    }
}

export function translateTags(tag: string) {
    switch (tag) {
        case 'sale':
            return 'Venda';
        case 'rent':
            return 'Locação';
        case 'vacation_rental':
            return 'Temporada';
        default:
            return tag;
    }
}

export function translateOrderBy(order: string) {
    switch (order) {
        case 'neighborhood':
            return 'Bairro';
        case 'city':
            return 'Cidade';
        case 'bedroom':
            return 'Dormitórios';
        case 'suite':
            return 'Suítes';
        case 'garage':
            return 'Vagas';
        case 'useful_area':
            return 'Área';
        case 'sale_value':
            return 'Valor';
        case 'rental_value':
            return 'Valor';
        default:
            return order;
    }
}

export function translateStatus(status: string): string {
    switch (status) {
        case 'in_construction':
            return 'Em Construção';
        case 'ready':
            return 'Pronto';
        case 'launch':
            return 'Lançamento';
        case 'pre_launch':
            return 'Pré lançamento';
    }
}

export const filter = (opt: string[], value: string): string[] => {
    const filterValue = value.toLowerCase();
    return opt.filter(item => normalize(item).toLowerCase().includes(normalize(filterValue)));
};

export const uniqueArray = (array: string[]) => {
    const unique = new Set(array);
    return [...unique];
};

export const findIndexArray = (value: number, rangeArray: number[]): number => {
    return rangeArray.indexOf(rangeArray.reduce((prev, curr) => {
        let valuePrev = 0;
        let valueCurr = 0;
        Object.keys(prev).forEach(key => valuePrev = prev[key]);
        Object.keys(curr).forEach(key => valueCurr = curr[key]);
        return (Math.abs(valueCurr - value) < Math.abs(valuePrev - value) ? curr : prev);
    }));
};

export const normalizeParams = (term: string) => {
    return term ? term.replace(/-/g, ' ').trim() : '';
};

export const normalizeSendParams = (term: string) => {
    return term ? term.replace(/ /g, '-').trim() : '';
};

export const normalizeSearch = (term: string) => {
    return term ? term.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '';
};

export const normalize = (term: string) => {
    const accentMap = {
        á: 'a', à: 'a', ã: 'a',
        é: 'e', è: 'e', ê: 'e',
        í: 'i', ì: 'i', ó: 'o',
        ò: 'o', ô: 'o', õ: 'o',
        ú: 'u', ù: 'u', ü: 'u',
        ç: 'c'
    };
    let ret = '';
    for (let i = 0; i < term.length; i++) {
        ret += accentMap[term.charAt(i)] || term.charAt(i);
    }
    return ret;
};

export const isNumber = (term) => {
    const numeros = '0123456789';
    for (let i = 0; i < term.length; i++) {
        if (numeros.indexOf(term.charAt(i), 0) !== -1) {
            return true;
        } else {
            return false;
        }
    }
};

export function isEmailValid(email): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function textContains(text: string, searchTerm: string): boolean {
    if (!searchTerm || !text) {
        return false;
    }
    return text.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
}

export function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export function componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
}

export function rgbToHex(r, g, b) {
    return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function getStringDate(stringDate: string) {
    const year = stringDate.split('-')[0];
    const month = stringDate.split('-')[1];
    const day = stringDate.split('-')[2];
    const date = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
    return date;
}

export function convertDateStringToFullDate(stringDate: string, dateFormat: string) {
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const days = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'];
    let date;
    let fullDate: string;

    (dateFormat === 'date')
        ? date = stringDate.split(' ')
        : date = getStringDate(stringDate);

    if (dateFormat === 'abbreviatedDate') {
        fullDate = ((date.getDate() <= 9) ? '0' + date.getDate() : date.getDate()) + ' ' + months[(date.getMonth())].slice(0, 3) + ' ' + date.getFullYear();
    } else if (dateFormat === 'extensiveDate') {
        fullDate = days[date.getDay()] + ', ' + ((date.getDate() <= 9) ? '0' + date.getDate() : date.getDate()) + ' de ' + months[(date.getMonth())] + ' de ' + date.getFullYear();
    } else {
        fullDate = date[5] + '-' + (months.indexOf(date[3]) + 1) + '-' + date[1];
    }
    return fullDate;
}

export function isColorDark(color: string): boolean {
    function colorNameToHex(colorName: string): string | null {
        const ctx = document.createElement("canvas").getContext("2d");
        if (ctx) {
            ctx.fillStyle = colorName;
            return ctx.fillStyle; // If it's a valid color name, return the hexadecimal code
        }
        return null;
    }

    // calculate the luminance
    function luminance(r: number, g: number, b: number): number {
        const a = [r, g, b].map((v) => {
            v /= 255;
            return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    }

    const hexPattern = /^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{8}|[a-fA-F0-9]{3}|[a-fA-F0-9]{4})$/;
    let hexColor = '';
    let alpha = 1;

    if (hexPattern.test(color)) {
        hexColor = color.replace('#', '');

        if (hexColor.length === 3) {
            hexColor = hexColor.split('').map((c) => c + c).join('');
        } else if (hexColor.length === 4) {
            hexColor = hexColor.split('').map((c) => c + c).join('');
        }

        if (hexColor.length === 8) {
            alpha = parseInt(hexColor.substring(6, 8), 16) / 255;
            if (alpha === 0) return true;
            hexColor = hexColor.substring(0, 6);
        }
    } else {
        // Try to convert the color name to hexadecimal
        let colorHex = colorNameToHex(color);
        if (!colorHex) {
            colorHex = '#ffffff'
        }
        hexColor = colorHex.replace('#', '');
    }

    // Convert hexadecimal to RGB values
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate luminance
    let lum = luminance(r, g, b);
    lum = alpha * lum + (1 - alpha);

    // The color is considered dark if the luminance is less than 0.5
    return lum < 0.5;
}