<imobzi-button-close-modal (closeChange)="closeDialog($event)"></imobzi-button-close-modal>

@if (!isSmallScreen) {
  <section id="schedule-visit-desktop" class="mat-dialog-content">
    <div id="schedule_visit-container" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      @if (!stepVisit) {
        <imobzi-talk-broker
          class="form-schedule_visit background-light col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          [data]="data.property">
        </imobzi-talk-broker>
      }
      @if (!stepVisit) {
        <div class="circle border-rd-50 color-light"
          fxLayout="row" fxLayoutAlign="center center"
          >
          OU
        </div>
      }
      <imobzi-schedule-visit
        [dates]="dates"
        [data]="data.property"
        [formType]="data.formType"
        [stepVisit]="stepVisit"
        (stepNext)="onStepNext($event)">
      </imobzi-schedule-visit>
    </div>
  </section>
}

@if (isSmallScreen) {
  <section id="schedule-visit-mobile" class="mat-dialog-content">
    <div id="schedule_visit-container" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <imobzi-talk-broker-mobile
        [data]="data"
        (closeDialog)="closeDialog($event)">
      </imobzi-talk-broker-mobile>
      <imobzi-schedule-visit-mobile
        [dates]="dates"
        [data]="data.property"
        [formType]="data.formType"
        (closeDialog)="closeDialog($event)">
      </imobzi-schedule-visit-mobile>
    </div>
  </section>
}
