@if (numberWhatsapp && showWhatsapp) {
  <button
    class="number relative btn btn-md btn-whatsapp-1 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
    title="Entre em contato"
    (click)="onPhoneClicked(numberWhatsapp)">
    <fa-icon [icon]="['fab', 'whatsapp']"></fa-icon>WhatsApp
  </button>
}
@if (numberPhone && showPhone) {
  <button
    class="number relative btn btn-md btn-primary-2 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
    title="Entre em contato"
    (click)="onPhoneClicked(numberPhone)">
    <fa-icon [icon]="['fas', 'phone']"></fa-icon>Ligar
  </button>
}