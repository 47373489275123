
<mat-dialog-content>
  <div class="dialog-header">
    <imobzi-button-close-modal id="closeFilter" (closeChange)='closeModal($event)'></imobzi-button-close-modal>
    <table>
      <tbody>
        <tr>
          @for (day of days; track day) {
            <td>{{day}}</td>
          }
        </tr>
      </tbody>
    </table>
  </div>

  <section id="calendar-dialog" class="mat-dialog-content">
    @if (!isSmallScreen) {
      <imobzi-calendar-mobile
        [isSmallScreen]="isSmallScreen"
        [startDateSelected]="startDateSelected"
        [endDateSelected]="endDateSelected"
        [propertyCalendar]="propertyCalendar"
        (searchDates)="onSearchDates($event)">
      </imobzi-calendar-mobile>
    }
  </section>

</mat-dialog-content>