import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { RequestService } from './request.service';
import { Observable, Subject } from 'rxjs';
import { Content } from '../interface/content';

@Injectable({
    providedIn: 'root'
})
export class ContentService {

    private contentConfigurationSubject = new Subject<Content>();
    public contentConfiguration$ = this.contentConfigurationSubject.asObservable();
    public contentConfiguration: Content;

    constructor(
        private appService: AppService,
        private requestService: RequestService
    ) {
        this.contentConfiguration$.subscribe(contentConfiguration => {
            this.contentConfiguration = contentConfiguration;
        });
    }

    public getContent(contentUrl: string): Observable<Content> {
        return new Observable<Content>(observer => {
            this.requestService.get<Content>(
                `${this.appService.apiUrl()}/${this.appService.namespace()}/site-content`, `url=${contentUrl}`).subscribe(
                    content => {
                        observer.next(content);
                        this.contentConfigurationSubject.next(content);
                    },
                    error => observer.error(error),
                    () => observer.complete()
                );
        });
    }

    getSitePreviewData(key: string): Observable<Content> {
        return new Observable<any>(observer => {
            this.requestService.get(`${this.appService.apiUrl()}/site-preview/${key}`).subscribe(
                response => {
                    observer.next(response);
                    observer.complete();
                },
                error => {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }
}
