<div>
    @switch (properties.status) {
        @case ('with_proposal') {
            <div class="tag-content">
                Em Negociação
            </div>
        }
        @case('rented') {
            <div class="tag-content">
                Reservado
            </div>
        }
        @default {
            @if (properties.stage && parameters.show_release_tag) {
                <div class="tag-content">
                {{translateStatus(properties.stage)}}
                </div>
            }
        }
    }
</div>
