<div id="address-container">
  <i class="material-icons-outlined">room</i>

  @if (parameters) {
    <h4 class="h4">
      <span class="address">{{ parameters.address }}</span>
      <span class="address_complement">{{(parameters.address_complement)? ", " + parameters.address_complement: ''}}</span>
      <span class="neighborhood">{{(parameters.neighborhood)? " - " + parameters.neighborhood: ''}}</span>
      <span class="city">{{(parameters.city)? " - " + parameters.city: ''}}</span>
      <span class="state">{{(parameters.state)? " - " + parameters.state: ''}}</span>
    </h4>
  }
</div>
