<imobzi-section-title
  [title]="title"
  [subtitle]="subtitle"
  [class.step_success]="displaySuccess">
</imobzi-section-title>

@if (!displaySuccess) {
  <imobzi-step-form
    [loading]="loading"
    [formContent]="formContent"
    [buttonTitle]="buttonTitle"
    [loading]="loading"
    (formSubmit)="onFormSubmit($event)">
  </imobzi-step-form>
}

@if (displaySuccess) {
  <imobzi-success-form
    [msgSuccessTitle]="msgSuccessTitle"
    [msgSuccessSubtitle]="msgSuccessSubtitle">
  </imobzi-success-form>
}