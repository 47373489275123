import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'imobzi-date-visit',
    templateUrl: './date-visit.component.html',
    styleUrls: ['./date-visit.component.scss']
})
export class DateVisitComponent implements OnInit {

    @Output() day = new EventEmitter();
    @Output() schedule = new EventEmitter();
    @Output() nextButton = new EventEmitter();

    days = [
        { abbr: 'Dom', name: 'domingo' },
        { abbr: 'Seg', name: 'segunda-feira' },
        { abbr: 'Ter', name: 'terça-feira' },
        { abbr: 'Qua', name: 'quarta-feira' },
        { abbr: 'Qui', name: 'quinta-feira' },
        { abbr: 'Sex', name: 'sexta-feira' },
        { abbr: 'Sáb', name: 'sábado' }
    ];

    schedules = ['Manhã', 'Tarde'];
    public nextDay;

    constructor() { }

    ngOnInit() {
        this.getDate();
        this.onScheduleChange('Manhã');
        this.onDayChange(this.nextDay);
    }

    getDate() {
        const date = new Date();
        this.nextDay = date.getDay() + 1;
        if (this.nextDay === 1) { this.nextDay = 'segunda-feira'; }
        if (this.nextDay === 2) { this.nextDay = 'terça-feira'; }
        if (this.nextDay === 3) { this.nextDay = 'quarta-feira'; }
        if (this.nextDay === 4) { this.nextDay = 'quinta-feira'; }
        if (this.nextDay === 5) { this.nextDay = 'sexta-feira'; }
        if (this.nextDay === 6) { this.nextDay = 'sábado'; }
        if (this.nextDay === 7) { this.nextDay = 'domingo'; }
    }

    onDayChange(day: string) {
        this.day.emit(day);
    }

    onScheduleChange(schedule: string) {
        this.schedule.emit(schedule);
    }

    onNextChange() {
        this.nextButton.emit(true);
    }

}
