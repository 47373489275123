import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: 'home',
        redirectTo: '',
        pathMatch: 'full'
    },
    {
        path: 'a/:database',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
    },
    {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'site-home-preview/:id',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'anuncie-seu-imovel',
        loadChildren: () => import('./pages/advertise-property/advertise-property.module').then(m => m.AdvertisePropertyModule)
    },
    {
        path: 'peca-seu-imovel',
        loadChildren: () => import('./pages/request-property/request-property.module').then(m => m.RequestPropertyModule)
    },
    {
        path: 'trabalhe-conosco',
        loadChildren: () => import('./pages/join-in-us/join-in-us.module').then(m => m.JoinInUsModule)
    },
    {
        path: 'buscar',
        loadChildren: () => import('./pages/search/search.module').then(m => m.SearchModule)
    },
    {
        path: ':availability/:city/:neighborhood',
        loadChildren: () => import('./pages/search/search.module').then(m => m.SearchModule)
    },
    {
        path: ':availability/:city/:neighborhood/:type',
        loadChildren: () => import('./pages/search/search.module').then(m => m.SearchModule)
    },
    {
        path: 'corretor/:nickname',
        loadChildren: () => import('./pages/search/search.module').then(m => m.SearchModule)
    },
    {
        path: 'conteudo/:id',
        loadChildren: () => import('./pages/content/content.module').then(m => m.ContentModule)
    },
    {
        path: 'site-preview/:id',
        loadChildren: () => import('./pages/content/content.module').then(m => m.ContentModule)
    },
    {
        path: 'empresa',
        loadChildren: () => import('./pages/content/content.module').then(m => m.ContentModule)
    },
    {
        path: 'nossos-servicos',
        loadChildren: () => import('./pages/content/content.module').then(m => m.ContentModule)
    },
    {
        path: 'parceiros',
        loadChildren: () => import('./pages/content/content.module').then(m => m.ContentModule)
    },
    {
        path: 'financiamento',
        loadChildren: () => import('./pages/content/content.module').then(m => m.ContentModule)
    },
    {
        path: 'documentacao',
        loadChildren: () => import('./pages/content/content.module').then(m => m.ContentModule)
    },
    {
        path: 'suspended',
        loadChildren: () => import('./pages/suspended/suspended.module').then(m => m.SuspendedModule)
    },
    {
        path: 'pagina-nao-encontrada',
        loadChildren: () => import('./pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
    },
    {
        path: 'imovel/codigo/:code',
        loadChildren: () => import('./pages/details/details.module').then(m => m.DetailsModule)
    },
    {
        path: 'c/:code',
        loadChildren: () => import('./pages/details/details.module').then(m => m.DetailsModule)
    },
    {
        path: 'imovel/:urlPath',
        loadChildren: () => import('./pages/details/details.module').then(m => m.DetailsModule)
    },
    {
        path: 'property/:urlPath',
        loadChildren: () => import('./pages/details/details.module').then(m => m.DetailsModule)
    },
    {
        path: '**',
        loadChildren: () => import('./pages/content/content.module').then(m => m.ContentModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
